.archive-page {
    width: 100%;
    height: 100%;
    min-height: 30rem;
    padding: 8rem 1rem;
    box-sizing: border-box;
}

.archive-container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
}

.archive-container table {
    width: 100%;
    height: 100%;
}

.archive-container table td {
    width: 30%;
    padding: 1rem 0;
}

.archive-container table td:nth-child(1) {
    width: 10%;
}

.archive-container table .button-group {
    display: flex;
}

.archive-container table .button-group .view-btn a {
    margin-right: 2rem;
    color: var(--site-blue);
    cursor: pointer;
}

.archive-container table .button-group .un-archive-btn {
    color: red;
    cursor: pointer;
}

@media screen and (min-width: 1000px) {

    .archive-page {
        width: 100%;
        height: 100%;
        min-height: 30rem;
        padding: 6rem 1rem 10rem;
        box-sizing: border-box;
    }

    .archive-container {
        width: 40%;
        height: 100%;
        margin: 0 auto;
    }
    
}