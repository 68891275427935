.register-page {
    width: 100%;
    height: 100%;
    padding: 8rem 1rem;
    box-sizing: border-box;
    position: relative;
    border: blue 4px solid;
}

.register-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.register-container h1 {
    margin: .5rem 0 2rem;
}

.register-container form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.register-container form label, 
.register-container form input {
    margin-bottom: .5rem;
}

.register-container form label {
    font-weight: 400;
}

.password-wrapper {
    width: 100%;
    position: relative;
}

.password-wrapper img {
    position: absolute;
    right: 1rem;
    top: .45rem;
    cursor: pointer;
    opacity: .4;
    width: 1.5rem;
}

.register-container form input {
    width: 100%;
    padding: .5rem .5rem;
    font-size: 1rem;
    font-family: "Outfit",  sans-serif;
    font-weight: 300;
    box-sizing: border-box;
}


.register-page .success-message {
    width: 95%;
    min-height: 10rem;
    height: auto;
    top: 30%;
    left: 2.5%;
    transform: translateX(-50%);
    animation: showMessage 600ms ease-out forwards;
    background-color: var(--site-blue-gray);
    color: white;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
    border-radius: .5rem;
    padding: 2rem;
    opacity: 0;
    box-sizing: border-box;
    position: absolute;
}

@keyframes showMessage {
    0% {
        transform: translateY(-2rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }    
}

.register-page .success-message .message {
    text-align: center;
    line-height: 2;
}

.register-page .success-message .ok-btn {
    width: 4rem;
    height: 2rem;
    border: 1px white solid;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .2rem;
    margin-top: 1rem;
    cursor: pointer;
}

.invalid-password {
    font-size: 1rem;
    color: var(--site-call-for-action);
}

.register-container form button {
    margin: 1rem 0;
    padding: .8rem 1rem;
    width: 10rem;
    background-color: var(--site-mid-gray);
    font-size: 1rem;
}


@media screen and (min-width: 600px) {
    .register-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .register-container form {
        width: 50%;
    }


    .register-page .success-message {
        width: 50%;
        left: 25%;
    }
}

@media screen and (min-width: 1000px) {
    .register-container {
        width: 50%;
    }

    .register-page .success-message {
        width: 40%;
        left: 30%;
    }
}