.wrapper-select-text {
    width: 100%;
    padding: 0;
}

.wrapper-select-text label {
    font-size: 1.2em;
    margin: .5em 0;
    font-weight: inherit;
}

.wrapper-select-text input {
    width: 100%;
    height: 2.5rem;
    border-radius: 2rem;
    margin: 1rem 0;
    outline: none;
    border: 1px gray solid;
    font-size: 1em;
    font-weight: inherit;
    padding: .5em 1em;
    box-sizing: border-box;
}

.wrapper-select-text .dropdown-menu {
    width: 100%;
    height: 100%;
    position: relative;
}

.wrapper-select-text .dropdown-menu::after {
    content: '';
    width: 1rem;
    height: 1rem;
    background-size: contain; 
    background-repeat: no-repeat; 
    left: calc(100% - 2rem);
    top: 50%;
    transform: translateY(-50%);
    position: absolute;   
    color: gray;
    font-size: .7rem;
}

.wrapper-select-text .dropdown-menu::after {
    color: gray
}


.wrapper-select-text .dropdown-menu input {
    cursor: pointer;
} 

.wrapper-select-text .form-dropdown-list {
    width: 100%;
    height: auto;
    max-height: 15rem;
    overflow: hidden;
    border-radius: 2rem;
    position: absolute;
    top: 3rem;
    left: 0;
    z-index: 10;
    display: flex;
    padding: 0;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 10px 25px rgba(62, 30, 4, 0.22);
    overflow: auto;
}

.wrapper-select-text .form-dropdown-list li {
    width: 100%;
    height: 100%;
    margin: 0;
    list-style: none;
    padding: 1.2rem 0 1.2rem 2rem;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px rgb(229, 229, 229) solid;
}

.wrapper-select-text .form-dropdown-list li:hover {
    background-color: var(--hoverColor);;
    color: white;
}

