.cart-section {
    width: 100%;
    height: 100%;
    padding: 10em 0 3em;
}
    
.cart {
    width: 85%;
    height: 100%;
    margin: 3em auto;
}
    
.cart-item-card {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 12% 88%;
    grid-template-rows: 1fr;
    box-sizing: border-box;
    margin: 1em 0;
}

.cart-item-image {
    width: 7em;
    height: 7em;
    border-radius: 1em;
    overflow: hidden;
}

.cart-item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 15% ;
}

.cart-item-description {
    display: grid;
    grid-template-columns: 15% 20% 17% 25% 13% 10%;
    grid-template-rows: 1fr;
    box-sizing: border-box;
}

.cart-item-title {
    font-weight: 600;
}

.cart-item-quantity {
    display: flex;
}

.cart-item-quantity .plus, 
.cart-item-quantity .minus {
    width: 1.5em;
    height: 1.5em;
    background-color: var(--site-background);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -.25em .5em auto;
    font-size: 1.2em;
    cursor: pointer;
}

.cart-item-quantity span:nth-of-type(1) {
    font-weight: 600;
    margin: 0 1em 0 0;
}

.cart-item-delete {
    text-align: right;
}

.cart-item-price span:nth-of-type(1) {
    font-weight: 600;
    margin: 0 1em 0 0;
}

.cart-item-total span:nth-of-type(1) {
    font-weight: 600;
    margin: 0 1em 0 0;
}


.cart-item-delete img {
    width: 1.8em;
    margin-top: -.25em; 
}


.totalToPay {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    box-sizing: border-box;
}

.subtotal, .shipping, .tax, .total {
    font-size: 1.2rem;
    margin-bottom: .5rem;
}

.subtotal span:nth-of-type(1),
.shipping span:nth-of-type(1),
.tax span:nth-of-type(1),
.total span:nth-of-type(1) {
    font-weight: 600;
    margin-right: 1em;
}

.checkout {
    margin: 2rem 0;
}

.checkout .checkout-btn {
    background-color: var(--site-orange);
}

.checkout .checkout-btn:active {
    transform: translateY(2px);
}