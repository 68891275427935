.store-section {
    width: 100%;
    height: 100%;
    padding: 8rem 0 3em;
}

.store-section .header {
    font-weight: 800;
    font-size: 2.5rem;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 0 .5rem 1rem;
    box-sizing: border-box;
}

.bonfire-logo {
    width: 14rem;
    margin: 0 auto;
}

.bonfire-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.store-section .store-list {
    margin: 3rem auto;
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    gap:  4rem;
    box-sizing: border-box;
}

.store-card {
    width: 100%;
    height: 100%;
    padding: .5rem;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.store-card .store-card-image {
    width: 100%;
    height: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.store-card .store-card-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.store-card .store-card-product-name {
    width: 100%;
    padding: 1rem 0;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
}

.store-card .store-card-product-link {
    width: 100%;
    padding: .5rem 1rem;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    transition: 800ms ease;
}

.store-card .store-card-product-link a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    box-sizing: border-box;
}

.store-card .store-card-product-link .link-explore {
    font-size: .85rem;
    padding: 1rem;
    border-radius: 2rem;
    letter-spacing: .1rem;
    transition: 800ms ease;
    box-sizing: border-box;
    opacity: .5;
}

.store-card .store-card-product-link img {
    width: 3rem;
    transition: 800ms ease;
    z-index: 10;
}

.store-card .store-card-product-link:hover {
    transform: scale(1.05);
}

.store-card .store-card-product-link:hover img {
    transform: rotate(45deg);
}

.store-card .store-card-product-link:hover .link-explore {
    transform: translateX(.5rem);
}

@media screen and (min-width: 600px) {
    .store-section .header {
        font-weight: 800;
        font-size: 3rem;
    }

    .store-section .store-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 760px) {

    .store-section {
        padding: 8rem 0 3em;
    }
    
    .store-card .store-card-product-name {
        width: 100%;
        padding: 1rem 4rem;
        box-sizing: border-box;
    }
}

@media screen and (min-width: 1000px) {
    .store-section {
        padding: 8rem 0 3em;
    }

    .store-section .store-list {
        grid-template-columns: repeat(3, 1fr);
    }
}
