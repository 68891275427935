.footer-section {
    width: 100%;
    height: 100%;
    background-color: var(--site-dark-gray);
    padding: 2rem 1em 8rem;
    color: rgb(255, 255, 255);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    position: relative;

}

.footer-col {
    width: 100%;
    height: auto;
    margin: 1rem 1rem 3rem;
    padding: 0 1em 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
}

.footer-section .footer-col .logo {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
}

.footer-section .footer-col .logo a {
    width: 100%;
    height: 100%;
}

.footer-section .footer-col .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.footer-section .footer-col .logo .logo-svg-footer {
    width: 100%;
}

.footer-section .footer-col .logo .logo-svg-footer path {
    fill: white;
}

.footer-section .footer-col .logo-svg-footer .cls-22 {
    stroke: white;
    stroke-width: .5px;
}

.footer-section .footer-col .vision {
    font-size: 1rem;
    font-weight: 400;
    margin: 2rem 0 3rem;
    line-height: 1.5;
    padding: 0;
    box-sizing: border-box;
}

.footer-section .footer-col .social-media img {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0 1em 0 0;
}

.rights {
    width: 100%;
}

.footer-col .header {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0 0 1em 0;
}

.footer-col .wrapper {
    display: flex;
    flex-direction: column;
}

.footer-col .wrapper a, .footer-col .wrapper div {
    text-decoration: none;
    color: rgba(255, 255, 255);
    font-weight: 100;
}

.footer-col .wrapper a, .footer-col .wrapper div a {
    margin: .5rem 0;
}

.footer-col .wrapper div img {
    margin: .5rem 1rem .5rem 0;
}

.rights-reserved {
    width: 100%;
    max-width: 80vw;
    padding: 5rem 1rem 0rem;
    text-align: left;
    position: absolute;
    bottom: 1rem;
    left: 0;
    font-weight: 100;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.rights-reserved span:first-of-type {
    font-size: .8rem;
    opacity: .6;
    margin: 0 0 .5rem;
}

.rights-reserved span:last-of-type a {
    color: white;
    font-size: .6rem;
    opacity: .4;
}

.rights-reserved span {
    margin: 0.05rem 0;
}

.footer-col .email,
.footer-col .phone,
.footer-col .message {
    display: flex;
    align-items: center;
} 


.footer-col .email img,
.footer-col .phone img,
.footer-col .message img {
    margin: 0;
    width: 1.8em;
    height: auto;
    margin-right: 1rem;
}


.admin-page-link {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    display: flex;
    align-items: center;
    opacity: .3;
}

.admin-page-link a {
    font-size: 1rem;
}

.admin-page-link a img {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: .5rem;
}

@media screen and (min-width: 370px) {

    .footer-section .footer-col .logo {
        width: 15em;
    }
}

@media screen and (min-width: 600px) {

    .footer-section {
        width: 100%;
        padding: 2rem 5% 8rem 1rem;
        display: flex;
        flex-direction: column;
    }

    .footer-section .footer-col {
        margin: 1rem;
        padding: 0 1rem;
    }

    .footer-section .footer-col .vision {
        width: 60%;
        padding: 0;

    }

    .rights-reserved {
        max-width: 100vw;
        text-align: center;
    }
    
}
@media screen and (min-width: 1000px) {

    .footer-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        padding: 2rem 2rem 10rem;
    }

    .footer-col {
        width: auto;
    }

    .footer-section .footer-col:nth-of-type(1) {
        width: 50%;
    }
    
    .footer-section .footer-col:nth-of-type(2) {
        width: 30%;
        padding: 0 0 0 1em;
        box-sizing: border-box;
    }
    
    .footer-section .footer-col:nth-of-type(3) {
        width: 25%;
        padding: 0 0 0 1em;
        box-sizing: border-box;
    } 


    .footer-section .footer-col .vision {
        font-size: .85rem;
        font-weight: 400;
        margin: 1.5rem 0 3rem;
        line-height: 1.5;
        width: 75%
    }

    
    .footer-section .footer-col .logo {
        width: 18rem;
    }

    .rights {
        width: 30%;
    }

    .rights-reserved {
        justify-content: center;
    }

    .footer-col .wrapper a, .footer-col .wrapper div {
        font-size: .8rem;
    }


}

@media screen and (min-width: 1700px) {
    .footer-section {
        font-size: 1em;
    }

    .footer-section .footer-col .vision {
        width: 60%;
    }
}