.individual-team-page {
    width: 100%;
    height: 100%;
    padding: 6rem 1rem;
    box-sizing: border-box;
}

.individual-team-container {
    width: 100%;
    height: 100%;
}

.individual-team-page .navigate-back {
    margin: .5rem auto 2.5rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.individual-team-page .navigate-back, 
.individual-team-page .navigate-back a {
    color: var(--site-dark-gray)
}

.individual-team-page .navigate-back a {
    cursor: pointer;
}

.individual-team-page .navigate-back a:hover {
    color: var(--site-orange)
}

.individual-team-page .navigate-back img {
    width: 1.1rem;
    margin: .2rem .25rem 0;
}

.individual-team-container .individual {
    width: 100%;
    height: 100%;
    margin: 0 auto;
} 

.individual-team-container .photo {
    width: 80%;
    max-width: 18rem;
    margin: 2rem auto;
    max-height: 22rem;
    overflow: hidden;
    border-radius: 1rem;
}

.individual-team-container .photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
}

.individual-team-container .name {
    font-weight: 600;
    font-size: 2em;
    margin-bottom: .5rem;

}

.individual-team-container .role {
    font-weight: 400;
    font-size: 1.5em;
    margin-bottom: 1rem;
}

.individual-team-container .blurb {
    line-height: 2;
}

@media screen and (min-width: 500px) {
    .individual-team-container .individual {
        width: 95%;
    } 
}

@media screen and (min-width: 600px) {

    .individual-team-page {
        padding: 10rem 2rem;
    }

    .individual-team-page .navigate-back {
        width: 100%;
    }

    .individual-team-container .individual {
        width: 100%;
        max-width: 43rem;
    } 
  
    .individual-team-container .photo {
        width: 40%;
        max-width: 13rem;
        float: left;
        height: 18rem;
        max-height: auto;
        margin: 0 2rem 0 0;
    }

    .individual-team-container .content-group {
        width: 100%;
    }

    .individual-team-container .name {
        font-weight: 600;
        font-size: 1.8em;
        margin-bottom: .5rem;
    
    }
    
    .individual-team-container .role {
        font-weight: 400;
        font-size: 1.2em;
        margin-bottom: 1rem;
    }
}


@media screen and (min-width: 1000px) {
    .individual-team-page {
        padding: 8rem 1rem;
    }

    .individual-team-page .navigate-back {
        width: 80%;
    }

    .individual-team-container .individual {
        width: 70%;
        display: flex;
        max-width: none;
    } 

    .individual-team-container .content-group {
        width: 70%;
    }

    .individual-team-container .photo {
        width: 30%;
        height: 18rem;
        aspect-ratio: auto;
        margin: 0 3rem 0 0;
    }
}