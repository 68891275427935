.interest {
    width: 100%;
}

.interest .sent-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 90%;
    height: 15rem;
    background-color: var(--site-mid-gray);
    color: white;
    z-index: 50;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
}

.interest .sent-message div {
    line-height: 2;
    text-align: center;
}

.interest .sent-message button {
    width: 6rem;
    margin-top: 1rem;
    background-color: var(--site-orange);
    border-radius: 2rem;
}

.interest-subheader {
    font-size: 1.2em;
    margin: 0 0 .5em;
    font-weight: 600;
    line-height: 2;
}

.interest form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.interest form label {
    margin: .5em 0;
}

.interest form input,
.interest form textarea,
.interest form .menu-dropdown-select
{
    width: 100%;
    border-radius: 1.5em;
    padding: .5em 1em;
    font-size: 1rem;
    font-weight: 200;
    font-family: "Outfit",  sans-serif;
    background-color: transparent;
    border: 1px solid var(--site-mid-gray);
    box-sizing: border-box;
}

.interest form .menu-dropdown-select {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.menu-dropdown-all-options {
    position: absolute;
    box-shadow: -1px 10px 15px rgba(0, 0, 0, .3);
    border-radius: 2em;
    background-color: white;
    color: var(--site-orange);
    overflow: hidden;
}

.menu-dropdown-option {
    width: 15em;
    padding: 1em 0 1em 3em;
    cursor: pointer;
}

.menu-dropdown-option:hover {
    background-color: var(--site-orange);
    color: white;
}

.interest form input[type="submit"] {
    margin: 2em 0;
    padding: .8em 2em;
    background-color: var(--site-mid-gray);
    color: white;
    width: 10rem;
    font-size: 1em;
    font-weight: 400;
}

@media screen and (min-width: 600px) {

    .interest .sent-message {
        position: fixed;
        width: 25rem;
    }
}

@media screen and (min-width: 1000px) {

    .interest form input,
    .interest form textarea,
    .interest form .menu-dropdown-select
    {
        width: 90%;
        box-sizing: border-box;
    }
    
}