.store-section {
    width: 100%;
    height: 100%;
    padding: 8rem 0 3em;
}

.store-section .header {
    font-weight: 800;
    font-size: 1.8rem;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 0 .5rem 1rem;
    box-sizing: border-box;
}


.shop-tabs {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    font-size: 1.5rem;
    padding: 1rem 0;
    position: relative;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 5;
    float: left;
}

.shop-tab-header {
    font-weight: 700;
    margin: 0 1.5rem;
    padding: .5rem 0;
    display: none;
}

.shop-tab {
    width: auto;
    height: auto;
    margin: 0 1.5rem;
    padding: .5rem 0;
    cursor: pointer;
    position: relative;
    font-weight: 400;
}

.shop-tab::before {
    content: '';
    width:var(--before-width);
    height: 100%;
    border-bottom: 2px var(--site-orange) solid;
    top: 0;
    left: 0;
    transition: width 0.3s ease;
    position: absolute;
} 

.shop-tab:hover::before {
    width: 100%;
}

.store {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    box-sizing: border-box;
}

.products {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.store-item-card {
    width: 80%;
    margin: 1rem auto;
    box-sizing: border-box; 
    transition: .8s ease-in-out;
    max-width: 20rem;
    display: grid;
    grid-template-rows: 4fr 1fr;
}

.store-item-image {
    width: 100%;
    aspect-ratio: 1;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: transform 800ms ease;
}

.store-item-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 800ms ease;
}

.store-item-image:hover img {
    transform: scale(1.1);
}

.store-item-description {
    width: 100%;
    height: 100%;
    padding: 0 0 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.store-item-card .store-item-title {
    width: 80%;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
}


.store-card-product-link {
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    transition: 800ms ease;
}

.store-card-product-link a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    box-sizing: border-box;
}

 .store-card-product-link .link-explore {
    font-size: .85rem;
    padding: 1rem;
    border-radius: 2rem;
    letter-spacing: .1rem;
    transition: 800ms ease;
    box-sizing: border-box;
    opacity: .5;
}

.store-card-product-link img {
    width: 3rem;
    transition: 800ms ease;
    z-index: 10;
}

.store-card-product-link:hover {
    transform: scale(1.05);
}

.store-card-product-link:hover img {
    transform: rotate(45deg);
}

.store-card-product-link:hover .link-explore {
    transform: translateX(.5rem);
}


@media screen and (min-width: 760px) {

    .store-section {
        padding: 8rem 0 3em;
    }

    .store {
        display: flex;    
        margin: 4rem auto 3rem;
    }

    .shop-tabs {
        position: sticky;
    }

    .shop-tab {
        font-size: 1.3rem;
        padding: .5rem 0;
    }

    .shop-tab-header {
        display: block;
    }
    

    .store .products {
        display: flex;    
    }


    .store-section .header {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    .store-item-card {
        width: 40%;
        max-width: none;
    }


}

@media screen and (min-width: 1000px) {
    .store-section {
        padding: 8rem 0 3em;
    }


    .shop-tabs {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: 3rem auto;
    }

    .shop-tab-header {
        font-weight: 700;
        margin: 1rem 2rem;
        padding: .5rem 0;
    }

    .shop-tab {
        width: auto;
        height: auto;
        margin: 1rem 2rem;
        padding: .5rem 0;
        cursor: pointer;
        position: relative;
    }


    .store {
        width: 80%;
        height: 100%;
        margin: 2rem auto;
    }


    .store-section .header {
        font-weight: 800;
        font-size: 3rem;
        width: 100%;
        text-align: center;
        padding: 0 .5rem;
        margin-bottom: 0;
    }

    .store-item-card {
        width: 25%;
        margin: 2rem;
    }

    .store-item-image {
        width: 100%;
        aspect-ratio: 1;
        height: auto;
        overflow: hidden;
    }

    .store-item-description {
        width: 100%;
    }

    .store-item-card .store-item-title {
        font-weight: 600;
        font-size: 1.2rem;
    }
}