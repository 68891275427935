.about-section {
    padding: 5rem .5rem 10rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.about-block {
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
}

.about-paragraph {
    margin-bottom: 2rem; 
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: 1s ease;
}

.about-block .about-paragraph:nth-child(1),
.about-block .about-paragraph:nth-child(3),
.about-block .about-paragraph:nth-child(5) {
    flex-direction: column-reverse;
}

.about-block .about-block-text {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.about-block .about-block-text p {
    margin: 0 0 1rem;
    padding: 0;
} 

.about-block .about-block-text ul li {
    margin: 0 0 0 -1.5rem;
}

.about-block .about-block-text ul {
    margin: 0;
}

.about-block .title {
    font-size: 2em;
    padding: 0 0 1rem;
    font-weight: 600;
}

.about-block .about-block-text {
    line-height: 2;
}


.about-block .about-paragraph .image {
    width: 100%;
    height: 20rem;
    overflow: hidden;
    background-color: rgb(230, 230, 230);
}


.about-block .about-paragraph:nth-child(1) .image,
.about-block .about-paragraph:nth-child(3) .image,
.about-block .about-paragraph:nth-child(5) .image {
    border-radius: 0 30rem 30rem 0;
}

.about-block .about-paragraph:nth-child(2) .image,
.about-block .about-paragraph:nth-child(4) .image {
    border-radius: 30rem 0 0 30rem;
}


.about-block .about-paragraph .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.about-block .about-paragraph .content {
    padding-top: 2rem;
}



@media screen and (min-width: 360px) {
    .about-section {
        padding: 5em 1em 10rem;

    }

    .about-block .title {
        font-size: 2.5em;
    }

}

@media screen and (min-width: 375px) {
    .about-section {
        padding: 6rem .5em 10rem;

    }

    .about-section .team-header span {
        margin: auto 0;
    }
}


@media screen and (min-width: 400px) {
    .about-section {
        padding: 7rem .5em 10rem;

    }

    .about-block .title {
        font-size: 3em;
    }

 
}

@media screen and (min-width: 425px) {
    .about-section {
        padding: 7rem .5em;
    }
}

@media screen and (min-width: 760px) {

    .about-section {
        padding: 8rem 1em;
    }

    .about-block {
        width: 75%;
        height: 100%;
        flex-direction: column;
        margin-top: 3rem;
    }


    .about-section .team-header span {
        text-align: center;
    }

    .about-block .title {
        width: 100%;
        text-align: center;
    }

    .about-block .about-paragraph .image {
        height: 25rem;
    }
   
}


@media screen and (min-width: 1000px) {
    
    .about-section {
        padding: 7rem 1rem;
        font-size: 1rem;
    }

    .about-section .team-header, .about-section .team-block{
        width: 85%;
    } 


    .about-block {
        width: 85%;
        height: 100%;
    }

    .about-block .about-paragraph {
        flex-direction: row;
        margin: 2rem 0;
    }

    .about-block .about-paragraph:nth-child(1),
    .about-block .about-paragraph:nth-child(3),
    .about-block .about-paragraph:nth-child(5) {
        flex-direction: row;
    }

    .about-block .about-paragraph:nth-child(1) {
        margin-top: 0;
    }

    .about-block .about-paragraph .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
    }

    .about-block .about-paragraph .content,
    .about-block .about-paragraph .image {
        width: 50%;
    }

    .about-block .about-paragraph:nth-child(1) .content,
    .about-block .about-paragraph:nth-child(3) .content,
    .about-block .about-paragraph:nth-child(5) .content {
        padding: 0 2rem 0 0;
    }

    .about-block .about-paragraph:nth-child(2) .content,
    .about-block .about-paragraph:nth-child(4) .content {
        padding: 0 0 0 2rem;
    }

    .about-block .about-paragraph .image {
        height: 28rem;
        overflow: hidden;
    }

    .about-block .about-paragraph .image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .about-block .title {
        width: 100%;
        text-align: left;
        font-size: 2.5rem;
    }

}

@media screen and (min-width: 1700px) {
    .about-block {
        width: 85%;
        height: 100%;
        flex-direction: column;
    }

}