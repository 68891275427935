.nav-image {
    width: 100%;
    height: 11rem;
    position: absolute;
    overflow: hidden;
    z-index: -3;
    margin: -5px 0 0 0;
    padding: 0;

}

.nav-image img {
    width: 100%;
    height: 90%;
    object-fit: cover;
    object-position: 15% 0;
}

.navigation-bar {
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    position:absolute;
    padding: 1.5rem 0;
    z-index: 5;
    overflow: visible;
    box-sizing: border-box;
    font-size: 1rem;
    z-index: 100;
}

.navigation-bar .logo {
    margin: 0;
    transform: translateY(-.8rem) translateX(.5rem);
    opacity: 1;
    display: flex;
    align-items: flex-start;
}

.logo-icon-svg {
    width: 13rem;
    height: auto;
}

.logo-icon-svg path {
    fill: var(--site-logo-color);
}

.logo-icon-svg .cls-22 {
    stroke: var(--site-logo-stroke);
    stroke-width: .5px;
}

.red-letter path {
    fill: var(--site-logo-stroke);

}

.logo-icon-svg  [stroke]:not([stroke="none"]):not([stroke-width="0"])  {
    stroke: var(--site-logo-stroke);
    stroke-width: 1;
}

.navigation-bar .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sandwich-menu {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
}

.sandwich-menu img {
    width: 2rem;
    height: 2rem;
}

.all-nav-links {
    display: none;
}


 .navigation-bar .all-nav-links-mobile {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 80%;
    height: 80vh;
    overflow-y: scroll;
    right: 0;  
    position: absolute;
    background-color: var(--site-dark-gray);
    transform: translateY(3em);
    padding: 2em 1em;
    box-sizing: border-box;
}

.navigation-bar .all-nav-links-mobile .nav-wrapper {
    margin: 0;
    font-weight: 400;
    width: 100%;
    text-align: start;
    position: relative;
}

.navigation-bar .all-nav-links-mobile .nav-wrapper .nav-tab {
    padding: 1em;
    display: flex;
    align-items: flex-start;
    cursor: pointer; 
    font-weight: 500;   
}

.navigation-bar .all-nav-links-mobile .nav-wrapper .nav-tab a,
.navigation-bar .all-nav-links-mobile .nav-wrapper .nav-tab span {
    color: white;
}

.nav-tab a img {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    padding: 0;
}

.navigation-bar .all-nav-links-mobile .nav-wrapper .nav-tab .nav-arrow-expand img {
    display: none;
}

.navigation-bar .all-nav-links-mobile .nav-wrapper .dropdown-menu {
    display: flex;
    flex-direction: column;
    background-color: var(--site-dark-gray);
    width: auto;
    z-index: 3;
    padding: 0 0 0 1em;
} 


.navigation-bar .all-nav-links-mobile .nav-wrapper .dropdown-menu .dropdown-item {
    padding: 1.2em 1em;
    font-size: .9em;
    box-sizing: content-box;
}

.all-nav-links-mobile .dropdown-item a, .dropdown-item a:hover, .dropdown-item a:visited {
    color: rgba(255, 255, 255, 0.85);
    text-decoration: none;
}

.navigation-bar .all-nav-links-mobile button {
    padding: .6em 1.8em;
    font-size: 1.2rem;
    transform: translateY(0);
    border-radius: 2em;
    background-color: var(--site-orange);
}


.admin-link {
    width: 100%;
    height: 3rem;
    background-color: var(--site-orange);
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 2rem;
    box-sizing: border-box;
    font-weight: 500;
}

.admin-link a {
    color: white;
    display: flex;
    align-items: center;
}

.admin-link a img {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0 2rem;
}

@media screen and (min-width: 350px) {

}

@media screen and (min-width: 375px) {

    .logo-icon-svg {
        width: 14rem;
        height: auto;
    }

    .sandwich-menu img {
        width: 2.5rem;
        height: 2.5rem;
    }
}


@media screen and (min-width: 400px) {

    .logo-icon-svg {
        width: 16rem;
        height: auto;
    }

    .sandwich-menu img {
        width: 2.5rem;
        height: 2.5rem;
    }
}


@media screen and (min-width: 500px) {

    .logo-icon-svg {
        width: 16rem;
        height: auto;
    }

    .sandwich-menu img {
        width: 2.5rem;
        height: 2.5rem;
    }
    
}

@media screen and (min-width: 600px) and (min-height: 900px) {

    .logo-icon-svg {
        width: 17rem;
        height: auto;
    }

    .navigation-bar {
        height: 6rem;
        font-size: 1.5rem;
    }

    .nav-image {
        width: 100%;
        height: 11rem;
    }

    .nav-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 25% 0;
    }

    .sandwich-menu img {
        width: 3rem;
        height: 3rem;
    }




}

@media screen and (min-width: 800px) and (min-height: 900px) {
    .logo-icon-svg {
        width: 23rem;
    }

    .sandwich-menu img {
        width: 3.5rem;
        height: 3.5rem;
    }

    .nav-image {
        width: 100%;
        height: 13rem;
    }
}



@media screen and (min-width: 1100px) {

    .nav-image {
        width: 100%;
        height: 11rem;
    }

    .nav-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 25% 0;
    }


    .navigation-bar {
        width: 100%;
        height: 6rem;
        display: flex;
        justify-content: space-between;
        position:absolute;
        padding: 1.5rem 2rem;
        z-index: 5;
        overflow: visible;
        box-sizing: border-box;
    }
    
    .navigation-bar .logo {
        margin: 0;
        transform: translateY(-.8rem) translateX(0rem);
        opacity: 1;
        display: flex;
        align-items: flex-start;
    }
    
    .logo-icon-svg {
        width: 18rem;
        height: auto;
    }


     .navigation-bar .all-nav-links {
        display: none;
        flex-direction: row;
        width: 67%;
        align-items: flex-start;
        box-sizing: border-box;
        justify-content: space-between;
    }
    
    .navigation-bar .nav-wrapper {
        margin: 0;
        padding: 0;
        text-align: left;
        position: relative;
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: .97rem;
      }
    
    .navigation-bar .nav-wrapper .nav-tab {
        cursor: pointer; 
        height: 4rem;
        display: flex;
        align-items: flex-start;
        font-weight: 400;
    }
    
    
    .navigation-bar .nav-wrapper .nav-tab .nav-arrow-expand img {
        width: .7rem;
        height: .5rem;
        margin: 0 0 0 .5rem;
    }
    
    .navigation-bar .nav-wrapper .dropdown-menu {
        background-color: var(--site-dark-gray);
        width: 14rem;
        height: auto;
        z-index: 3;
        position: absolute;
        top: 2.5rem;
        display: none;
    } 
    
    
    .navigation-bar .nav-wrapper .dropdown-menu .dropdown-item {
        padding: 1.2em 1em;
        border-bottom: .5px solid rgba(255, 255, 255, 0.3);
        font-size: .9em;
        box-sizing: content-box;
        line-height: 1.5;
    }
    
    .navigation-bar .nav-wrapper:hover .dropdown-menu {
       display: block;
    }
    
    .navigation-bar .nav-wrapper:hover .dropdown-menu .dropdown-item {
        padding: 1.2em 1em;
        border-bottom: .5px solid rgba(255, 255, 255, 0.3);
        font-size: .9em;
        box-sizing: content-box;
    }
    
    .navigation-bar .nav-wrapper:hover .nav-tab .nav-arrow-expand img {
        transform: rotate(180deg);
    }
    
    .dropdown-item a, .dropdown-item a:hover, .dropdown-item a:visited {
        color: rgba(255, 255, 255, 0.85);
        text-decoration: none;
    }
    
    .navigation-bar button {
        padding: .6rem 1.8rem;
        font-size: .9rem;
        border-radius: 2rem;
        transform: translateY(-.75rem);
        cursor: pointer;
    }  

    .navigation-bar .all-nav-links {
        display: flex;
    }

    .sandwich-menu {
        display: none;
     }

     .navigation-bar .all-nav-links-mobile {
        display: none;
     }
}



@media screen and (min-width: 1200px) and (min-height: 800px) {
    .navigation-bar {
        padding: 2.25rem 2rem 0;
        align-items: flex-start;
    }

    .nav-image {
        width: 100%;
        height: 12rem;
    }

    .logo-icon-svg {
        transform: scale(1.1);
        height: auto;
        transform-origin: top left;
    }

    .navigation-bar .nav-wrapper .nav-tab {
        font-weight: 350;
    }    

    .navigation-bar .all-nav-links-mobile {
        display: none;
     }
}

@media screen and (min-width: 1020px) and (min-height: 1300px) and (max-width: 1200px) {
    .navigation-bar .sandwich-menu{
        display: flex;
     }

     .navigation-bar .all-nav-links {
        display: none;
     }

     .navigation-bar .all-nav-links-mobile {
        display: flex;
        flex-direction: column;
        margin: 0;
        width: 50%;
        height: 50vh;
        overflow-y: scroll;
     }
}


@media screen and (min-width: 1440px) {

    .navigation-bar {
        padding: 1.8rem 2rem 0;
        align-items: flex-start;
    }

    .logo-icon-svg {
        transform: scale(1.1);
        height: auto;
        transform-origin: top left;
    }

    .navigation-bar .nav-wrapper .nav-tab {
        font-weight: 400;
    }  

    .navigation-bar .nav-wrapper {
        margin: 0;
        padding: 0;
    }
}

@media screen and (min-width: 1700px) {
    .logo-icon-svg {
        width: 16rem;
        height: auto;
    }
    .nav-image {
        width: 100%;
    }
}

@media screen and (min-width: 1800px) {
    .logo-icon-svg {
        width: 18rem;
    }
}

@media all and (display-mode: fullscreen) {
    .navigation-bar {
        padding: 2.25rem 2rem 0;
        align-items: flex-start;
    }

    .logo-icon-svg {
        transform: scale(1.1);
        height: auto;
        transform-origin: top left;
    }

    .navigation-bar .nav-wrapper .nav-tab {
        font-weight: 350;
    }
}
