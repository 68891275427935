.printful-page {
    width: 100%;
    height: 100%;
    padding: 10rem 2rem;
    box-sizing: border-box;
}

.printful-page .heading {
    font-size: 4rem;
    font-weight: bold;
}

.printful-page .product {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, 1fr);
}

.printful-page .image {
    grid-column: 1 /  6;  
    grid-row: 1 /  6;     
    display: flex;
    justify-content: center;
    align-items: center;
}

.printful-page .product-info {
    grid-column: 7 / 11;  
    grid-row: 1 / 6;     
}

.printful-page .image img {
    width: 100%;       
    height: 100%;      
    object-fit: cover; 
}


.printful-page .product-info .name, 
.printful-page .product-info .color,
.printful-page .product-info .buy-btn {
    margin: .5rem 0; 
    font-size: 1.2rem;
}

.printful-page .product-info .name {
    font-size: 2rem;
    padding: 1rem 0;
}

.printful-page .product-info .select-size {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
}

.printful-page .product-info .price {
    margin: 1rem 0;
    font-size: 1.2rem;
}

.printful-page .product-info .add-to-cart {
    margin: 1rem 0;
    font-size: 1.2rem;
    background-color: orange;
    width: 8rem;
    padding: .8rem 1rem;
    display: flex;
    justify-content: center;
    text-align: center;
}

.printful-page .product-info .buy-btn {
    background-color: var(--site-light-gray);
    width: 4rem;
    font-size: 1.2rem;
    padding: .8rem 1rem;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: .5rem;
    cursor: pointer;
    border-radius: 5px;
}

.printful-page .product-info .buy-btn:active {
    transform: translateY(2px);
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.37);
}