.volunteer-section {
    width: 100%;
    height: 100%;
    padding: 7em 1em 6rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    column-gap: 0;
    margin: 0 auto;
    box-sizing: border-box;
}

.volunteer-section .wrapper-left, 
.volunteer-section .wrapper-right  {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
}

.volunteer-section .wrapper-left {
    padding: 0 0 2em;
}

.volunteer-section .wrapper-left .image-wrapper {
    width: 100%;
    height: 18em;
    margin: 1.5em 0 2em;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 2em;
    background-color: rgb(230, 230, 230);
}

.volunteer-section .wrapper-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.volunteer-section .wrapper-left .volunteer-header {
    font-size: 2em;
    font-weight: 600;
    color: var(--site-orange);
    text-align: center;
}

.volunteer-section .wrapper-left .volunteer-content {
    line-height: 2;
}

.volunteer-section .wrapper-left ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.volunteer-section .wrapper-left ul li {
    background-color: var(--site-orange);
    color: rgb(255, 255, 255);
    margin: .5em .5rem .5em 0;
    list-style: none;
    padding: 0 1em;
    border-radius: 1em;
}

.volunteer-section .wrapper-left ul .other-li {
    background-color: transparent;
    color: red;
}

.volunteer-section .wrapper-right .form {
    width: 50%;
}

@media screen and (min-width: 767px) {

    .volunteer-section {
        width: 95%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        column-gap: 2em;
        padding: 8em 0 6em;
    }
    
    .volunteer-section .wrapper-left .volunteer-header {
        font-size: calc(1.8rem + 1vw);
        text-align: left;
    }
}

@media screen and (min-width: 1000px) {

    .volunteer-section {
        width: 85%;
        column-gap: 5em;
        padding: 8rem 0 6em;
    }
    
}

@media screen and (min-width: 1200px) {

    .volunteer-section {
        width: 85%;
        column-gap: 5em;
        padding: 9rem 0 6em;
    }
    
}

@media screen and (min-width: 1200px) and (min-height: 800px) {

    .volunteer-section {
        width: 85%;
        column-gap: 5em;
        padding: 10rem 0 6em;
    }
    
}

@media screen and (min-width: 1700px) {

    .volunteer-section {
        width: 85%;
        padding: 10rem 1em 6rem;
    }
    
}