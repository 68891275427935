.news-page {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    padding: 10rem 1rem 6rem;
    box-sizing: border-box;
}

.news-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.news-card {
    width: 100%;
    margin: 0 0 3rem;
}

.news-card .image {
    width: 100%;
    height: 20rem;
    border-radius: 2rem;
    overflow: hidden;
    background-color: rgb(230, 230, 230);
}

.news-card .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news-card .content {
    width: 100%;
    height: 50%;
    box-sizing: border-box;
}

.news-card .content .title {
    padding: 2rem 0 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    height: auto;
}

.news-card .content .text {
    padding: 0;
    font-size: 1rem;
    line-height: 2;
}

.news-card .content .read-more-link {
    margin: 2rem 0 0;
    padding: 1rem 2rem;
    background-color: var(--site-dark-gray);
    width: 5rem;
    border-radius: 5rem;
}

.news-card .content .read-more-link:hover {
    background-color: var(--site-orange);
}

.news-card .content .read-more-link a {
    color: white;
}

@media screen and (min-width: 600px) {

    .news-card {
        width: 60%;
        margin: 0 auto 3rem;
    }
    
}

@media screen and (min-width: 1000px) {
    .news-section {
        width: 85%;
        height: 100%;
        margin: 0 auto;
    }

    .news-card {
        width: 100%;
        margin: 1.5rem 0;
        display: flex;
        height: 20rem;
        transition: 800ms ease-in-out;
        align-items: center;
        transform: scale(.95);
    }

    .news-card:hover .image img {
        transform: scale(1.05);
    }

    .news-card:hover  {
        transform: scale(1);
    }

    .news-card .image {
        width: 40%;
        height: 100%;
    }

    .news-card .image img {
        transform: scale(1);
        transition: 800ms ease-in-out;
    }

    .news-card .content {
        width: 60%;
        height: 100%;
        padding: 0 0 0 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .news-card .content .title {
        padding: 0 0 1rem;
    }
}