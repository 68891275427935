.funding-page {
    width: 100%;
    height: 100%;
    padding: 8rem 1rem 6rem;
    box-sizing: border-box;
}

.funding-page .submission-success {
    display: flex;
    width: 20rem;
    height: 15rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--site-mid-gray);
    color: white;
    position:fixed;
    margin: 0 50%;
    transform: translateX(-50%);
    padding: 2rem;
    box-sizing: border-box;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.4);
    border-radius: 2rem;
    z-index: 50;
}

.funding-page .submission-success div {
    text-align: center;
    line-height: 2;
    margin-bottom: 2rem;
}

.funding-page .submission-success button {
    font-size: 1rem;
    font-weight: 400;
    background-color: var(--site-blue);
    border-radius: 2rem;
}

.funding-page .submission-success button:active {
    transform: translateY(2px);
}

.funding-heading {
    font-size: 1.6rem;
    font-weight: 800;
    width: 100%;
    padding: 1rem .5rem 3rem;
    text-align: center;
    box-sizing: border-box;
}

.funding-content {
    width: 100%;
    height: 100%;
    line-height: 2;
}

.funding-content  .subheading {
    font-weight: 600;
    font-size: 5vw;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1.5rem;
}

.funding-content  .subheading img {
    width: 1.5rem;
    height: 1.1rem;
    margin-left: 1rem;
}

.funding-content .text-image {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: stretch;
}

.funding-content .paragraph {
    margin-top: 1rem;
}

.funding-content ol,
.funding-content ul {
    margin: 0;
}

.funding-content ol li,
.funding-content ul li {
    margin-left: -1rem;
}

.funding-content .image {
    width: 100%;
    aspect-ratio: 1.2;
    border-radius: 2rem;
    margin-bottom: 1rem;
    background-color: rgb(230, 230, 230);
    position: relative;
    overflow: hidden;
}


.funding-content .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;
}

.funding-content .application-content {
    padding-bottom: 2rem;
}

.funding-content form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: .5rem;
}

.funding-content form label  {
    font-weight: 400;
}

.funding-content form input,
.funding-content form textarea {
    width: 100%;
    padding: 0.8rem 1rem;
    margin: .5rem 0;
    border-radius: 4rem;
    font-family: "Outfit", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    box-sizing: border-box;
}

.year-graduated-dropdown, 
.citizenship-dropdown {
    position: relative;
}
.year-graduated-dropdown .dropdown-content,
.citizenship-dropdown .dropdown-content {
    position: absolute;
    left: 0;
    top: 90%;
    width: 100%;
    z-index: 5;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border-radius: 2rem;
}

.year-graduated-dropdown input,
.citizenship-dropdown input {
    cursor: pointer;
}

.year-graduated-dropdown .dropdown-content div,
.citizenship-dropdown .dropdown-content div {
    background-color: white;
    padding: .5rem 2rem;
    box-sizing: border-box;
    cursor: pointer;
}

.year-graduated-dropdown .dropdown-content div:hover,
.citizenship-dropdown .dropdown-content div:hover {
    background-color: var(--site-blue);
    color: white;
}

.funding-content input[type="file"] {
    font-size: .8em;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  display: none; 
}


input[type="date"]::-webkit-calendar-picker-indicator {
    display: none; /* For Chrome and Safari */
  }

input[type="date"] {
    appearance: none; /* Remove default styling for Firefox */
    -moz-appearance: none;
    -webkit-appearance: none;
    position: relative;
    padding-right: 1.5rem; /* Create space for custom icon */
  }


  input[type="date"]::after {
    content: url('https://img.icons8.com/material-outlined/24/calendar--v1.png');
    position: absolute;
    right: 1rem; 
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; 
    width: 24px;
    height: 24px;
  }


.funding-content form textarea {
    min-height: 6rem;
    border-radius: 2rem;
}

.funding-content form input[type="submit"] {
    width: 10rem;
    padding: .8rem 2rem;
    margin-top: 2rem;
    border: none;
    color: white;
}

.funding-content form input[type="submit"]:active {
    transform: translateY(2px);
    box-shadow: 2px 2px 5px rgba(28, 48, 78, 0.2);
}

.funding-content .mismatch {
    font-size: 1rem;
    color: red;
}

.funding-content .policy {
    margin-top: 1rem;
    font-size: .8em;
    display: flex;
}

.funding-content .policy input {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
    accent-color: var(--site-mid-gray);
}

.funding-content .dropdown-menu {
    width: 100%;
    height: 100%;
    position: relative;
}

.funding-content .dropdown-menu::after {
    content: '▼';
    width: auto;
    height:auto;
    left: calc(100% - 2rem);
    top: 55%;
    transform: translateY(-58%);
    position: absolute;   
    color: var(--site-orange);
    font-size: .7rem;
}

.high-school-page .funding-content .dropdown-menu::after {
    color: var(--site-blue)
}


.funding-content .dropdown-menu input {
    cursor: pointer;
} 

.funding-content .form-dropdown-list {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 2rem;
    position: absolute;
    top: 3.5rem;
    left: 0;
    z-index: 10;
    display: flex;
    padding: 0;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 10px 25px rgba(62, 30, 4, 0.22);
}

.funding-content .form-dropdown-list li {
    width: 100%;
    height: 100%;
    margin: 0;
    list-style: none;
    padding: 1.2rem 0 1.2rem 2rem;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px rgb(229, 229, 229) solid;
}

.funding-content .form-dropdown-list li:hover {
    background-color: var(--site-blue);
    color: white;
}

.undergrad-page  .funding-content .form-dropdown-list li:hover {
    background-color: var(--site-nav-student);
    color: white;
}

.parents-page .funding-content .form-dropdown-list li:hover {
    background-color: var(--site-orange);
    color: white;
}

.funding-content ul.years {
    max-height: 12rem;
    overflow-y: scroll;
}

.funding-content .text .intro {
    font-size: 1.5em;
    line-height: 1.5;
    font-weight: 600;
    text-align: center;
    width: 95%;
    margin: 2rem auto 0;
}


@media screen and (min-width: 600px) {
    .funding-page {
        padding: 10rem 1rem 6rem;
    }

    .funding-content  .subheading {
        font-size: 1.4rem;
    }
    
    .funding-content  .subheading img {
        width: 2.2rem;
        height: 1.3rem;
        margin-left: 1rem;
        margin-top: .5rem;
    }
}

@media screen and (min-width: 760px) {

    .funding-page {
        padding: 12rem 2rem 6rem;
    }

    .funding-content .image {
        width: 100%;
        aspect-ratio: 1.6;
    }

    .funding-content .text {
        width: 100%;
        box-sizing: border-box;
    }

    .funding-content .text-image {
        display: block;
    }
}

@media screen and (min-width: 1000px) {
    .funding-page {
        padding: 10rem 1rem 6rem;
    }
    
    .funding-heading {
        width: 80%;
        margin: 0 auto;
    }

    .funding-content {
        width: 60%;
        margin: 0 auto;
    }

    .funding-heading {
        font-size: 2.5rem;
    }

    .funding-content  .subheading {
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }

    .funding-content ol li,
    .funding-content ul li {
        margin-left: 0;
        line-height: 2;
    }

    .funding-content form {
        width: 50%;
    }

    .funding-content form input,
    .funding-content form textarea {
        padding: 0.5rem 1rem;
    }

    .funding-content .text-image {
        aspect-ratio: 1.9;
    }

    .funding-content .image {
        width: 100%;
        height: auto;
        aspect-ratio: 1.8;
        float:none;
     }

    .funding-content .text {
        width: 100%;
    }

    .funding-content  > div:nth-of-type(4)  > .image {
        float: left;
        margin: 0 2rem 0 0;
    }
    
    .funding-content .image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .funding-content .image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .high-school-page .funding-content .image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center -1rem;
    }
    
    .funding-content .form-dropdown-list {
        top: 3rem;
    }

    .funding-content .text .intro {
        font-size: 1.8em;
    }
}

.high-school-page  .funding-content .text .intro {
    color: rgba(31, 210, 255, 0.749);
}

.high-school-page .funding-heading {
    color: var(--site-blue);
}

.high-school-page .funding-content  .subheading {
    color: var(--site-blue);
}

.high-school-page .funding-content form input,
.high-school-page .funding-content form textarea {
    border: 1px var(--site-blue) solid;
}

.high-school-page .funding-content form input[type="submit"] {
    background-color: var(--site-blue);
}


.funding-page  .pop-privacy-policy {
    top: 10%;
    left: 5%;
    position: fixed;
    opacity: 1;
    width: 90%;
    height: 25rem;
    border-radius: .5rem;
    background-color: white;
    z-index: 100;
    padding: 2rem .5rem;
    margin: 0 auto;
    box-sizing: border-box;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.5);
    font-size: .8rem;
  }
  
  .funding-page   .pop-privacy-policy .close {
    width: 100%;
    text-align: right;
    font-weight: 600;
    cursor: pointer;
  }
  
  @media screen and (width: 600px) {
    .funding-page  .pop-privacy-policy {
      width: 60%;
      height: 30rem;
    }
  }
  
  @media screen and (min-width: 1000px) {
    .funding-page  .pop-privacy-policy {
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        height: 30rem;
        background-color: white;
        z-index: 100;
        padding: 2rem 1rem;
        box-sizing: border-box;
        box-shadow: 0 15px 45px rgba(0, 0, 0, 0.5);
      }
    
  }
  