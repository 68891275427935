.team-page {
    width: 100%;
    min-height: 100%;
    height: auto;
    padding: 3rem 0 10rem;
    box-sizing: border-box;
    overflow: hidden;
}

.team-header {
    width: 95%;
    height: 80vh;
    margin: 1rem auto;
    font-size: 2.8em;
    font-weight: 600;
    padding: 1rem 2rem 1rem 1rem;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    box-sizing: border-box;
}

.team-page .glyph {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 3rem 0 5rem;
}

.team-page .glyph img {
    animation: glyph-move 1.7s infinite;
}

.team-intro {
    width: 95%;
    margin: 0 auto 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 1rem;
    box-sizing: border-box;
    font-size: 1.5rem;
    line-height: 2;
    text-align: left;
}

.leadership-subheader {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 2rem;
}

.team-container {
    width: 100%;
    min-height: 30rem;
    height: 90vh;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    box-sizing: border-box;
    flex-direction: column;
}

.team-photo-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
}

.photo-block {
    width: 100%;
    height: 100%;
    margin: 3rem 0 2rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.photo-block a {
    width: 100%;
    max-width: 17rem;  
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-photo {
    width: 100%;  
    height: auto;
    aspect-ratio: .8;
    border-radius: 1rem;   
    overflow: hidden;
    margin: 1rem auto 2rem;
}

.team-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.photo-caption {
    width: 100%;
    padding: 1rem 0;
}

.photo-caption-name {
    font-size: 2rem;
    font-weight: 600;
}

.photo-caption-role {
    font-size: 1.5rem;
}

.photo-caption .read-more {
    opacity: 1;
    transition: 1s ease;
    transform: translateX(0);
    animation: arrow-move-team 1.2s infinite;
}

.photo-caption .read-more img {
    width: 3rem;
    height: auto;
    margin: 1rem 0;
}

.photo-block:hover .photo-caption-name {
    color: var(--site-orange);
}

.photo-block:hover .read-more {
    opacity: .7;
}

@keyframes arrow-move-team {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(.5rem);
    }
}

@keyframes glyph-move {
    0%, 100% {
        opacity: 0;
        transform: translateY(0);
    }    
    50% {
        opacity: 1;
        transform: translateY(2rem);
    }
}


@media screen and (min-width: 360px) {
    .team-header {
        width: 98%;
        font-size: 3.8em;    
    }

}

@media screen and (min-width: 375px) {
    .team-header {
        width: 98%;
        font-size: 3.8em;    
    }    
}



@media screen and (min-width: 400px) {
    .team-header {
        width: 90%;
        font-size: 3.8em;   
        padding-top: 3rem; 
    }
}

@media screen and (min-width: 425px) {
    .team-header {
        width: 90%;
        font-size: 4rem;
        margin: 2rem auto;
    }

    .leadership-subheader {
        font-size: 2.2rem;
    }
}

@media screen and (min-width: 500px) {
    .leadership-subheader {
        font-size: 2.8rem;
    }
}


@media screen and (min-width: 550px) {

    .team-container {
        width: 90%;
    }

    .team-page .glyph {
        display: none;
    }

    .team-header {
        min-height: 6rem;
        justify-content: center;
        text-align: center;
        font-size: 2.3rem;
        margin: 2rem auto 1rem;
        height: auto;   
    }

    .team-intro {
        text-align: center;
        font-size: 1.1rem;
    }

    .leadership-subheader {
        display: none;
    }

    .photo-caption-name {
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    .photo-caption-role {
        font-size: 1rem;
    }

    .photo-block {
        max-width: 35rem;
        margin: 3rem auto 0;
    }

    .photo-block a {
        max-width: 100%;
        flex-direction: row;
        align-items: flex-start;
    }

    .team-photo {
        width: 50%;
        max-width: 10rem;
        height: auto;
        aspect-ratio: .7;
        margin: 0.5rem 3rem 0 0;
        border-radius: 1rem;
        overflow: hidden;
        position: relative;
    }

    .team-photo img {
        border-radius: 1rem;
    }

    .photo-caption {
        width: 50%;
        font-size: .8rem;
        padding: 0;
    }
    
}

@media screen and (min-width: 800px) {
    .team-header {
        margin: 4rem auto 2rem;
        height: auto;   
    }
}

@media screen and (min-width: 1000px) {
    .team-container {
        width: 80%;
        height: 100%;
    }

    .team-header {
        width: 85%;
        min-height: 5rem;
        height: 100%;
        font-size: calc(1.8em + 1vw);
        padding: 4rem 1rem 1rem;
        margin: 1rem auto;
    }

    .team-intro {
        width: 70%;
        margin: 0 auto 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        line-height: 2;
        text-align: center;
    }

    .photo-block {
        width: 100%;
        display: flex;
        margin: 3rem 0 0;
        box-sizing: border-box;
    }

    .team-photo {
        width: 13rem;
        height: 14rem;
        display: flex;
        justify-content: flex-end;
        padding: 0 2rem 0 0;
    }

    .photo-caption {
        width: 55%;
        height: 8rem;
        padding: 1rem 0 0 1rem;
    }
    
    .photo-caption-name {
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    .photo-caption-role {
        font-size: 1.2rem;
    }

    .photo-caption .read-more {
        opacity: 0;
    }
}