.thank-you-page {
    width: 100%;
    height: 100%;
    min-height: 80vh;
    padding: 8rem 1rem;
    box-sizing: border-box;
}

.thank-you-container {
    width: 50%;
    height: 100%;
    margin: 0 auto;
}