.undergrad-page  .funding-content  .subheading {
    color: var(--site-nav-student);
}

.undergrad-page .funding-heading {
    color: var(--site-nav-student);
}

.undergrad-page .funding-content form input,
.undergrad-page .funding-content form textarea {
    border: 1px var(--site-nav-student) solid;
}

.funding-content input:focus {
    outline: 0;
}

.undergrad-page .funding-content form input[type="submit"] {
    background-color: var(--site-nav-student);
}

.undergrad-page video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 2rem;
}

.undergrad-page .mismatch {
    font-size: 1rem;
    color: red;
}

.undergrad-page .dropdown-menu {
    width: 100%;
    height: 100%;
    position: relative;

}

.undergrad-page .dropdown-menu input {
    cursor: pointer;
} 

.undergrad-page .form-dropdown-list {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 2rem;
    position: absolute;
    top: 3rem;
    left: 0;
    z-index: 10;
    display: flex;
    padding: 0;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 10px 25px rgba(62, 30, 4, 0.22);
}

.undergrad-page .form-dropdown-list-year {
    height: 15rem;
    overflow-y: scroll;
}

.undergrad-page .form-dropdown-list li {
    width: 100%;
    height: 100%;
    margin: 0;
    list-style: none;
    padding: 1.2rem 0 1.2rem 2rem;
    box-sizing: border-box;
    cursor: pointer;
}

.undergrad-page .form-dropdown-list li:hover {
    background-color: var(--site-nav-student);
    color: white;
}

.undergrad-page .funding-content .text .intro {
    color: rgba(254, 160, 35, 0.8);
}

.undergrad-page .funding-content .image {
    width: auto;
    height: auto;
    aspect-ratio: 1.9;
    margin: 0 auto;
}

.undergrad-page .funding-content .image video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;
}


.undergrad-page .funding-content .text .intro {
    font-size: 1.3em;
    line-height: 1.5;
    font-weight: 600;
    text-align: center;
    width: 90%;
    margin: 2rem auto 0;
}

@media screen and (min-width: 760px) {
    .undergrad-page .funding-content .text-image {
        align-items: flex-start;
    }

}

@media screen and (min-width: 1000px) {
    .undergrad-page .funding-content .text-image {
        display: block;
    }

    .undergrad-page .funding-content .image {
        border-radius: 2rem;
        width: 100%;
        height: 100%;
        margin: 0 auto 1.5rem;
    }
    

    .undergrad-page .funding-content .text {
        width: 100%;
    }    

    .undergrad-page .funding-content .text .intro {
        font-size: 1.8em;
    }
}

