

.home-section-two  {
    width:100%;
    height: 32rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--site-background);
}


.home-section-two .donate-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.home-section-two .donate-content .banner-donate-text-image {
    width: 100%;
    height: 100%;
    padding: 0;
}

.home-section-two .donate-content .banner-donate-text-image img {
    width: 130%;
    height: 130%;
    object-fit: cover;
    object-position: -3rem 15%;
}

.home-section-two .banner-donate-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    box-sizing: border-box;
    position: absolute;
    padding: 1rem;
}

.home-section-two .banner-donate-text-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    line-height: 150%;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    padding: 1rem;
    width: 12rem;
    background-color: rgb(250, 204, 0, .9);
    background-color: var(--site-deep-red);
    background-color: #e70000cb;
    box-sizing: border-box;
    border-radius: 12px;
    font-size: 1rem;
}

.home-section-two  .banner-donate-text-heading span {
    display: inline;
    font-weight: 600;
    color: white;
    font-size: 1.5em;
}

.home-section-two button {
    background-color: var(--site-call-for-action);
    font-size: 1rem;
    margin: 1rem 0 0 0;
    font-weight: 600;
    width: 12rem;
    height: 4rem;
    padding: .8rem 1rem;
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    font-family: 'Montserrat', sans-serif;
    border-radius: 2rem;
    background-color: rgba(255, 255, 255, 0.8);
    color: var(--site-deep-red);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-top: 1.5rem;
    overflow: hidden;

}

.home-section-two button:hover .text {
    transform: translateX(-10rem);

}

.home-section-two button:hover .circle {
    width: 12rem;
}

.home-section-two button:hover .circle .text-over {
    transform: translateX(2.5rem);
    text-align: left;
    opacity: 1;
}

.home-section-two button .text {
    width: 75%;
    transition: 600ms ease;
    opacity: 1;
    transform: translateX(0);
}

.home-section-two button .circle {
    background-color: red;
    color: white;
    height: 4rem;
    width: 4rem;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 600ms ease;
    padding: 1rem;
    box-sizing: border-box;
}

.home-section-two button .circle .text-over {
    padding: 1rem 1rem;
    text-align: center;
    font-size: 1em;
    transform: translateX(11rem);
    transition: opacity 1200ms ease, transform 1000ms ease;
    opacity: 0;
    position: absolute;
    min-width: 10rem;
}

.home-section-two button .circle img {
    width: 2rem;
    height: 2rem;
    animation: heartbeat 2.2s infinite ease-in-out;
}

@keyframes heartbeat {
    0% {
      transform: scale(1); /* Normal size */
    }
    15% {
      transform: scale(1.16); /* Quick expansion */
    }
    30% {
      transform: scale(1); /* Quick contraction */
    }
    45% {
      transform: scale(1.12); /* Slight expansion */
    }
    60% {
      transform: scale(1); /* Back to normal size */
    }
    100% {
      transform: scale(1); /* Normal size at the end of the cycle */
    }
  }


@media screen and (min-width: 500px) {

    .home-section-two .banner-donate-text {
        padding: 1rem 1rem 2rem;
    }

    .home-section-two .donate-content .banner-donate-text-image {
        width: 90%;
        height: 100%;
        margin-left: auto;
    }

    .home-section-two .donate-content .banner-donate-text-image img {
        width: 120%;
        height: 120%;
        object-fit: cover;
        object-position: -1em calc(15% - 2vw);
    }
    
}

@media screen and (min-width: 767px) {

    .home-section-two  {
        height: 35rem;
    }


    .home-section-two .banner-donate-text {
        align-items: flex-start;
        justify-content: center;
    }


    .home-section-two .banner-donate-text-heading {
        font-size: 1.2rem;
        width: 15rem;

    }

    .home-section-two  .donate-content {
        flex-direction: row;
    }
    
    .home-section-two  .donate-content .banner-donate-text-image {
        width: 70%;
        min-width:auto;
        height: 100%;
    }

    .home-section-two .donate-content .banner-donate-text-image img {
        width: 130%;
        height: 130%;
        object-fit: cover;
        object-position: -3rem 15%;
    }
}

@media screen and (min-width: 1000px) {

    .home-section-two  {
        width: 100%;
        height: 32rem;
        padding: 0 2rem;
    }

    .home-section-two .donate-content {
        width: 80%;
        flex-direction: row;
    }

    .home-section-two .donate-content .banner-donate-text-image {
        width: 70%;
        height: 100%;

    }

    .home-section-two  .donate-content .banner-donate-text-image img {
        width: 130%;
        height: 130%;
        object-fit: cover;
     }

    .home-section-two .banner-donate-text-heading {
        font-size: 1.8rem;
        font-weight: 600;
        width: 18rem;
        padding: 0;
        background-color: transparent;
    }

    .home-section-two .banner-donate-text-heading span {
        font-size: 1.4em;
        font-weight: 800;
        color:red;
    }

    .home-section-two .banner-donate-text {
        width: 50%;
        transform: translateY(-.6em);
        align-items: flex-start;
        justify-content: center;
        z-index: 4;
        transform: translateY(0);
        box-sizing: border-box;
        position: static;
    }

    .home-section-two button {
        font-size: 1.6rem;
        padding: 1rem 1.5rem;
        margin: 2rem 0 0 0;
        font-weight: 400;
        width: 15rem;
        height: 4rem;
    }

    .home-section-two button:hover .circle {
        width: 15rem;
    }
    
    .home-section-two button:hover .circle .text-over {
        transform: translateX(1rem);
        text-align: center;
        opacity: 1;
    }
  
}


