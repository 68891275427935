.store-top {
    width: 85%;
    height: 100%;
    padding: 0 0 2rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(60%, auto) 1fr;
    box-sizing: border-box;
}

.store-header {
    font-size: 2.5em;
    font-weight: 600;
    text-align: center;
    padding: 0 0 3rem;
}

.store-cart {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.store-cart a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

 .store-cart a span {
    font-weight: 600;
    padding: 0 .5em 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

 .store-cart img {
    width: 2.5em;
    height: auto;
}

 .store-cart .shopping-qty {
    background-color: var(--site-background);
    width: 1.5em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
