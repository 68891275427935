.admin-welcome {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: .9rem;
    color: white;
}

.admin-welcome .welcome-group {
    width: auto;
    display: flex;
    align-items: center;
}

.admin-welcome .links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.admin-welcome h3 {
    font-weight: 600;
    margin-right: 2rem;
    display: none;
}

.admin-welcome .links .link {
    margin: 0 .25rem;
    display: flex;
    cursor: pointer;
}

.admin-welcome .links .link img {
    width: 1rem;
    height: 1rem;
    margin: 0 0 0 .25rem;
}

.admin-welcome .links .logout img {
    width: 1.5rem;
    height: 1.5rem;
}

.admin-id {
    position: relative;
} 

.admin-id img {
    width: 2rem;
    margin-left: 1rem;
}

@media screen and (min-width: 760px) {
    .admin-welcome {
        font-size: 1rem;
    }
    .admin-welcome h3 {
        font-weight: 600;
        margin-right: 2rem;
        display: flex;
    }

    .admin-welcome .links .link {
        margin: 0 .5rem;
        display: flex;
        cursor: pointer;
    }
    
}

@media screen and (min-width: 1000px) {

    .admin-welcome .links .link {
        margin: 0 1rem;
        display: flex;
    }
}