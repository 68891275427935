.why-donate {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.why-donate-title {
    font-size: 2.5rem;
    font-weight: 600;
    padding: 1rem 0 .5rem;
    text-align: center;
}

.why-donate-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
}

.why-donate-card {
    width: 90%;
    height: 100%;
    margin: 7rem auto 0;
    position: relative;
    padding: .5rem 1rem;
}

.why-donate-text-wrapper {
    padding: 15rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.why-donate-card .why-donate-heading {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1.5rem 0 0;
    width: 100%;
    text-align: center;
}

.why-donate-card .why-donate-text {
    line-height: 2;
    padding: 1.5em 0;
    width: 100%;
    text-align: center;
}

.why-donate .image-border {
    width: 16.5rem;
    height: 16.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(97, 97, 97, 0.12);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -3rem) scale(1);
    transition: 1s ease;
}

.why-donate-card .why-donate-image {
    width: 12rem;
    height: auto;
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 50%;
    background-color: rgb(240, 240, 240);
    border: 1rem var(--site-background)  solid;
    position: relative;
}

.why-donate-card .why-donate-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.why-donate-card:nth-child(2) .why-donate-image img {
    object-position: 80% 0;;
}

.why-donate-text-wrapper button {
    font-size: 1rem;
    margin: 2em 0;
    padding: .8rem 1rem;
    background-color: var(--site-mid-gray);
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    z-index: 0;
    position: relative;
    border-radius: .5rem;
}

.why-donate-text-wrapper button::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 30px;
    left: 50%;
    bottom: 50%;
    transform: translateY(50%);
    background-color: transparent;
    z-index: 1;
}

.why-donate-text-wrapper button:hover::after {
    animation: pulseButton 500ms forwards ease-out;
}

@keyframes pulseButton {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, .2);
    }
    100% {
        box-shadow: 0 0 0 80px rgba(255, 255, 255, 0);
    }
}

/* IMAGE BORDER ANIMATION */

.why-donate-card .image-border::before,
.why-donate-card .image-border::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transition: 0.6s ease;
}


.why-donate-card .image-border.ripple1::before {
    transform-origin: center;
    animation: ripple1 1.5s infinite ease-in-out ;
}

.why-donate-card:hover .image-border.ripple2::after {
    transform-origin: center;
    animation:  ripple2 1.5s infinite ease-in-out .5s;
}

@keyframes ripple1 {
    0% {
        box-shadow: 0 0 0 0 rgba(100, 100, 100, .1);
    }
    70% {
        box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes ripple2 {
    0% {
        box-shadow: 0 0 0 0 rgba(102, 102, 102, 0.1);
    }
    70% {
        box-shadow: 0 0 0 40px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}



@media screen and (min-width: 767px) and (max-width: 999px) {

    .why-donate {
        width: 100%;
        padding: 0 .5em;
    }

    .why-donate-card .why-donate-heading {
        font-size: 1.5rem;
        font-weight: 600;
        padding: 1.8rem 0 0;
    }
    
    .why-donate-card {
        width: 95%;
        height: 100%;
        display: grid;
        grid-template-columns: 40% 60%;
        grid-template-rows: 1fr;
        box-sizing: border-box;
        align-items: center;
        margin-top: 3rem;
        padding: 0 1rem;
        gap: 2.5rem;
    }   
 
    .why-donate-text-wrapper {
        width: 100%;
        padding: 1rem 2rem 1rem 0;
        box-sizing: border-box;
        align-items: flex-start;
    }

    .why-donate-card .why-donate-heading, 
    .why-donate-card .why-donate-text {
        text-align: left;
    }
    .why-donate .image-border {
        width: 16.5rem;
        height: 16.5rem;
        flex-shrink: 1;
        position: static;
        transform: translate(0, 0);
    }

    .why-donate-text-wrapper button {
        font-size: 1rem;
        z-index: 0;
    }
}


@media screen and (min-width: 1000px) {

    .why-donate {
        width: 90%;
    }

    .why-donate-body {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 3.5rem 0 2rem;
    }
    
    .why-donate-card {
        width: 28%;
        height: 100%;
        margin: 6rem auto 0;
        transition: 1000ms ease;               
    }    
    
    .why-donate-card .image-border {
        transform: translate(-50%, -4rem);
    }

    .why-donate-card .why-donate-heading {
        text-align: center;
    }

    
    .why-donate-text-wrapper {
        align-items: flex-start;
        padding: 15rem 0 0;
    } 

    .why-donate .image-border {
        width: 16.5rem;    
    }
    
    .why-donate-card .why-donate-image {
        width: 12.5rem;
    }

    .why-donate-card .why-donate-text {
        text-align: left;
        line-height: 2;
    }

    .why-donate-text-wrapper button {
        font-size: 1rem;
    }
}