.admin-link {
    width: 100%;
    height: 3rem;
    background-color: var(--site-orange);
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem;
    box-sizing: border-box;
    font-weight: 500;
}

.admin-link a {
    color: white;
    display: flex;
    align-items: center;
}

.admin-link a img {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0 2rem;
}

@media screen and (min-width: 600px) {
    .admin-link {
        padding: 0 2rem;
    }
}