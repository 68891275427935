.individual-applicant-page {
    width: 100%;
    height: 100%;
    padding: 8rem 2rem 7rem;
    box-sizing: border-box;
}

.individual-applicant-page table {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: .8rem;
}

.individual-applicant-page table td {
    padding: .5rem 1rem .5rem 0;
    box-sizing: border-box;
    vertical-align: top;
    line-height: 1.5;
}

.individual-applicant-page table td:nth-child(1) {
    width: 50%;
}

.individual-applicant-page table .name {
    font-size: 2em;
}

.individual-applicant-page .pdf-download {
    height: 7rem;
    vertical-align: middle;
}

.individual-applicant-page .pdf-download button {
    padding: .8rem 2rem;
    background-color: var(--site-mid-gray);
    border: 1px var(--site-mid-gray) solid;
}

@media screen and (min-width: 600px) {
    .individual-applicant-page {
        padding: 6rem 1rem;
    }

    .individual-applicant-page table {
        width: 90%;
        margin: 1rem auto;
        box-sizing: border-box;
        font-size: 1rem;
    }
}

@media screen and (min-width: 1000px) {
    .individual-applicant-page {
        padding: 6rem 1rem;
    }

   
    .individual-applicant-page table {
        width: 55%;
        margin: 1rem auto;
    }

    .individual-applicant-page table td:nth-of-type(1) {
        padding-right: 3rem;
    }

    .individual-applicant-page table td:nth-of-type(2) {
        width: 40%;
    }
    
}
