.privilages-page {
    width: 100%;
    height: 100%;
    padding: 8rem 1rem;
    box-sizing: border-box;
    font-size: .9rem;
}

.privilages-container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
}

.privilages-container h1 {
    margin-bottom: 2rem;
}

.privilages-container .table-container {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
}


.privilages-container table {
    width: 100%;
    min-width: 1000px;
    border-collapse: collapse;
}

.privilages-container table tbody tr:nth-of-type(odd) {
    border-bottom: 1px var(--site-mid-gray) solid;
}

.privilages-container th {
    background-color: rgb(230, 230, 230);
    border-right: 5px white solid;
}

.privilages-container th, .privilages-container td {
    min-width: 20%;
    padding: 1rem;
    text-align: left;
    word-wrap: break-word;
    word-break: break-all;
}

.privilages-container td {
    background-color: rgb(245, 245, 245);
    border: 4px white solid;
}

.privilages-container td:nth-child(1),
.privilages-container td:nth-child(2),
.privilages-container td:nth-child(3),
.privilages-container td:nth-child(4) {
     white-space: nowrap
}

.privilages-container td:nth-child(1),
.privilages-container td:nth-child(3) {
    font-weight: 600;
}

.privilages-container td:nth-child(5) {
    text-align: center;
}

.privilages-container td:nth-child(6) {
    text-align: center;
}


.privilages-container td label {
    width: 60%;
    display: flex;
    margin: .5rem 0;
}

.privilages-container td input {
    width: 1.5rem;
}

.privilages-container td input[type="radio"] {
    accent-color: var(--site-call-for-action);
}

.privilages-container .role-group {
    display: flex;
    justify-content: space-between;
}

.privilages-container .save-changes button {
    width: 6rem;
    padding: .8rem 1rem;
    color: var(--site-mid-gray);
    font-size: .9rem;
    font-weight: 400;
    background-color: transparent;
    border: 1.5px var(--site-mid-gray) solid;
    cursor: pointer;
}

.privilages-container .delete-user button {
    width: 6rem;
    padding: .8rem 1rem;
    color: var(--site-call-for-action);
    font-weight: 400;
    background-color: transparent;
    border: 1.5px var(--site-call-for-action) solid;
    cursor: pointer;
    font-size: .9rem;
}
.privilages-container .comments {
    margin-top: 4rem;
}

.privilages-container .comments ul li {
    margin: .5rem 0;
}

@media screen and (min-width: 1000px) {
    .privilages-page {
        padding: 6rem 1rem 8rem;
    }
    
}