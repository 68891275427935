
.parents-page .funding-heading {
    color: var(--site-orange);
}

.parents-page .funding-content  .subheading {
    color: var(--site-orange);
}

.parents-page .funding-content form input,
.parents-page .funding-content form textarea {
    border: 1px var(--site-orange) solid;
}

.parents-page .funding-content form input[type="submit"] {
    background-color: var(--site-orange);
}

.parents-page .mismatch {
    color: red;
}


.parents-page .funding-content .text {
    margin-top: 0rem;
}

.parents-page .intro-parent {
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    color: var(--site-mid-gray);
    line-height: 1.5;
    margin-bottom: 2rem;
}

.parents-page .intro-parent span {
    font-size: 1.2em;
    color: rgb(255, 0, 0, 0.7);
}

@media screen and (min-width: 600px) {
    .parents-page .intro-parent {
        font-size: 1.3em;
    }
}

@media screen and (min-width: 1000px) {
    .parents-page .funding-content .text {
        margin-top: 2rem;
    }    

    .parents-page .intro-parent {
        font-size: 1.3em;
    }

    .parents-page .funding-content .image {
        aspect-ratio: 1.5;
    }
}