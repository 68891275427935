.page-404 {
    width: 100%;
    height: 100;
    min-height: 30rem;
    padding: 10rem 1rem;
    box-sizing: border-box;
}

.container-404 {
    width: 90%;
    height: 100%;
    margin: 0 auto;
}

.container-404 a {
    display: flex;
    align-items: center;
}

.container-404 a img {
    width: 2.5rem;
    margin-left: 1rem;

}