.admin-page {
    width: 100%;
    height: 100%;
    padding: 6rem .5rem 7rem 1.5rem;
    box-sizing: border-box;
}

.admin-page .archive-alert {
    width: 15rem;
    height: 13rem;
    background-color: var(--site-mid-gray);
    color: white;
    padding: 2rem;
    position: fixed;
    left: 50%;
    top: 30%; 
    transform: translate(-50%, -30%);
    z-index: 50;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.admin-page .archive-alert div {
    padding: .5rem 0;
    text-align: center;
}

.admin-page .archive-alert div:nth-child(1) {
    line-height: 2;
} 

.admin-page .archive-alert button {
    margin: .25rem;
    background-color: var(--site-call-for-action);
    font-size: 1rem;
    padding: .5rem 1.5rem;
}

.admin-page .archive-alert button:nth-child(2) {
    background-color: var(--site-blue);
}

.admin-page .list-high-school {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.admin-page .list-high-school h1 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.admin-page .list-high-school h2 {
    font-size: 1.1rem;
}

.admin-page table {
    width: 95%;
    height: 100%;
    margin: 0 auto;
}

.admin-page table tr td {
    width: 28%;
    height: 100%;
    padding: 1rem 0;
}

.admin-page table tr td:nth-child(1) {
    width: 10%;
}

.admin-page .button-group {
    width: 100%;
    display: flex;
}

.admin-page .button-group div {
    margin: 0 1rem;
}

.admin-page .view-btn a {
    color: var(--site-blue);
    cursor: pointer;
}

.admin-page .archive-btn {
    color: var(--site-call-for-action);
    cursor: pointer;
}

@media screen and (min-width: 600px) {
    
    .admin-page {
        padding: 7rem 1rem 6rem 3rem;
    }

    .admin-page table {
        width: 90%;
        height: 100%;
    }
}


@media screen and (min-width: 760px) {

    .admin-page {
        padding: 8rem 1rem 6rem;
    }

    
    .admin-page .archive-alert {
        width: 20rem;
        height: 14rem;
    }

    .admin-page .archive-alert button {
        margin: .5rem;
        background-color: var(--site-call-for-action);
        font-size: 1rem;
        padding: .5rem 2rem;
    }
    
    .admin-page .list-high-school {
        width: 70%;
        padding: 1rem;
        height: 100%;
        margin: 0 auto;
    }

    .admin-page .welcome {
        width: 70%;
        margin: 0 auto;
    }
    
    .admin-page .list-high-school h1 {
        font-size: 2rem;
    }
    
    .admin-page .list-high-school h2 {
        font-size: 1.5rem;
    }
    
    .admin-page table {
        width: 100%;
        height: 100%;
    }
    
    .admin-page table tr td:nth-child(1) {
        width: 5%;
    }
}

@media screen and (min-width: 1000px) {

    .admin-page {
        padding: 6rem 1rem 10rem;
    }

    .admin-page .list-high-school {
        width: 50%;
        padding: 1rem;
        height: 100%;
        margin: 0 auto;
    }

}