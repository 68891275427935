.home-section-five {
    width: 100%;
    height: 100%;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr ;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    box-sizing: border-box;
    margin-top: 10rem;
}

.home-section-five-col {
    width: 90%;
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem 2rem;
    box-sizing: border-box;
    margin: .5rem  auto;
    transition: 500ms ease-in-out; 
    position: relative;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.home-section-five-col:first-of-type {
    border: none;
    z-index: 0;
}

.home-section-five-col .overlay {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.025);
} 

.home-section-five-col:nth-child(1) .overlay {
    background-color: rgba(255, 0, 0, 0.6);
}

.home-section-five-col .title,
.home-section-five-col .content,
.home-section-five-col .apply {
  position: relative; 
  z-index: 2; 
}

.home-section-five-col:nth-child(1) {
    color: white;
    padding: 0;
    position: relative;
}

.home-section-five-col:nth-child(1) img {
    position:absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 35%;
}

.home-section-five-col .title {
    font-size: 1.6rem;
    padding-bottom: 1rem;
    font-weight: 600;
    z-index: 2;
}

.home-section-five-col .content {
    width: 100%;
    height: 100%;
    line-height: 1.5;
    padding: 1rem 0 .5rem;
}

.home-section-five-col:nth-child(1) .content {
    padding: 0 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.home-section-five-col .apply {
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    align-items: center; 
    margin-top: 0;
    transform: translateY(1.5rem);
}

.home-section-five-col .apply img {
    width: 3rem;
    height: 3rem;
    transition: 1s ease;
}

.home-section-five-col .apply .how-to-apply {
    font-size: 1.1rem;
    font-weight: 200;
    padding: 1rem 0;
    opacity: 1;
    transition: 800ms ease;
    transform: translateX(1rem);
}

.home-section-five-col .apply:hover img {
    transform: rotate(45deg);
} 


@media screen and (min-width: 760px) {
    .home-section-five {
        width: 100%;
        margin: 0 auto;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        padding: 0 1em;
        grid-gap: 1em;
    }

    .home-section-five-col {
        min-height: 15em;
        height: 100%;
        padding: 1.5em 2em 1.5em 2em;
        display: flex;  
        width: 100%;
        box-sizing: border-box;
    }
}


@media screen and (min-width: 1000px) {
    .home-section-five {
        width: 80%;
        gap: 2rem;
    }

    .home-section-five-col {
        min-height: none;
        padding: 1.5rem 2rem;
        border-radius: 1rem;
    }
    
    .home-section-five-col:nth-of-type(1) .content {
        padding: 2rem;
    }
    
    .home-section-five-col .col-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .home-section-five-col .col-wrapper .title {
        font-size: 1.6rem;
    }


    .home-section-five-col .content {
        width: 100%;
        box-sizing: border-box;
    }

    .home-section-five-col:nth-child(1) .content {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 2rem;
        font-size: 1.8rem;
    }

    .home-section-five-col .apply .how-to-apply {
        opacity: 0;
        transform: translateX(0);
    }

    .home-section-five-col .apply:hover .how-to-apply {
        opacity: .6;
        transform: translateX(.5rem);
    }

}

@media screen and (min-width: 1400px) {
    .home-section-five {
        width: 80%;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }

    .home-section-five-col {
        min-height: 18rem;
        height: 100%;
        margin: .5em;
        padding: 2em 1em 2em 2em;
        display: flex; 
        justify-content: center;  
    }

    .home-section-five-col .content {
        padding: 1rem 1.5rem 1rem 0;
    }
}
