.contact-us-page {
    width: 100%;
    height: 100%;
    padding: 8em 1em 8em;
    font-size: 1rem;
    box-sizing: border-box;
}

.contact-us-page .alert-sent-message {
    display: flex;
    width: 20rem;
    height: 15rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--site-mid-gray);
    color: white;
    position:fixed;
    margin: 0 50%;
    transform: translateX(-50%);
    padding: 2rem;
    box-sizing: border-box;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.4);
    border-radius: 2rem;
    z-index: 50;
}

.contact-us-page .alert-sent-message div {
    text-align: center;
    line-height: 2;
    margin-bottom: 2rem;
}

.contact-us-page .alert-sent-message button {
    background-color: var(--site-background);
    font-size: 1rem;
    font-weight: 400;
    border-radius: 2rem;
    color: var(--site-dark-gray);
}

.images-header-all {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.image-group {
    display: flex;
    width: 100%;
    min-height: 9rem;
    height: 100%;
}

.image-header {
    width: 100%;
    height: 100%;
    margin: .25rem;
    overflow: hidden;
}

.image-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: rgb(230, 230, 230);
    border-radius: 50%;
}

.image-header:nth-child(1),
.image-header:nth-child(2),
.image-header:nth-child(3) {
    display: none;
}

.contact-us-page .contact-us-heading {
    width: 100%;
    font-size: 2em;
    font-weight: 600;
    margin: 0 auto 2rem;
    text-align: center;
}

.contact-us-section {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
}

.contact-us-section .icon {
    width: auto;
    display: flex;
    align-items: center;
    margin: .5em 1.5rem 0 0;
    white-space: nowrap;
}

.contact-us-section .icon span {
    margin: auto .5rem;
}

.contact-us-section .icon img {
    width: 2rem;
    width: 2rem;
    margin: 0 .25em; 
}

.contact-form form {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 1rem;
    padding-top: 2rem;
}

.contact-form .form-header {
    font-weight: 600;
    margin: .5rem 0 1.5rem;
    font-size: 1.5rem;
}

.contact-form form label {
    margin: 0 0 .5rem;
    width: 100%;
}

.contact-form form input,
.contact-form form textarea {
    width: 100%;
    margin: 0 0 .5em;
    padding: .8em 1em;
    border-radius: 1.5em;
    border: 1px solid var(--site-dark-gray);
    font-weight: 200;
    font-family: "Outfit", sans-serif;
    font-size: 1em;
    box-sizing: border-box;
}

.contact-form form input[type="submit"] {
    width: 10rem;
    padding: .8em 2em;
    font-family: "Outfit",  sans-serif;
    font-size: 1em;
    border: 0;
    background-color: var(--site-dark-gray);
    color: white;
    margin: 2rem 0 0 0;
    font-weight: 400;
}

.contact-form form input[type="submit"]:active {
    transform: translateY(2px);
}

@media screen and (min-width: 600px) {

    .contact-us-section {
        width: 80%;
        margin: 0 auto;
    }

    .images-header-all {
        width: 100%;
        margin: 0 auto;
        flex-direction: row;
     }

    .image-header:nth-child(1),
    .image-header:nth-child(2),
    .image-header:nth-child(3) {
        display: block;
    }

    .contact-us-page .contact-us-heading {
        width: 50%;
    }

   
    .contact-details {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
     }


}

@media screen and (min-width: 800px) {

    .contact-us-section {
        width: 70%;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1000px) {

    .contact-us-section {
        width: 50%;
    }
}

