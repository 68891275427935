
.home-section-one {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 4rem;
    box-sizing: border-box;
}


.home-section-one .three-ways-to-help {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 .5em;
    margin: 1em auto;
    box-sizing: border-box;
}


.home-section-one .three-ways-to-help .way-to-help {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    line-height: 2;
    margin: 1rem 0;
    opacity: 1;
    font-weight: 300;
 }

 .card-way-to-help-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.8rem 0;
    box-sizing: border-box;
    font-size: 1rem;
 } 
 
.home-section-one .three-ways-to-help .way-to-help .home-card-way-to-help-icon {
    border-radius: 50%;
    display: flex;
    margin: 0;
}

.home-section-one .three-ways-to-help .way-to-help .home-card-way-to-help-icon img {
    width: 3.5em;
    height: 3.5em;
}

.home-section-one .three-ways-to-help  .way-to-help .card-heading {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0  0 .5em;
    text-align: center;
}

.home-section-one .three-ways-to-help  .way-to-help .card-body {
    text-align: center;
    padding: 0 1rem;
}


@media screen and (min-width: 760px) {
    .home-section-one .three-ways-to-help {
        width: 100%;
        flex-direction: row;
        padding: 0 .5em;
        justify-content: space-around;
        box-sizing: border-box;
    }

    .home-section-one .three-ways-to-help .way-to-help {
        width: 30%;
        margin: .5em;
        padding: 1em 0;
        box-sizing: border-box;
    }

    .home-section-one .three-ways-to-help  .way-to-help .card-heading {
        font-size: 1.2em;
        line-height: 1.5;
        font-weight: 600;
        padding: 0 0 .5em;
        text-align: center;
    }
    
    .home-section-one .three-ways-to-help  .way-to-help .card-body {
        text-align: center;
        padding: 0;
    }
    
}

@media screen and (min-width: 1000px) {
    .home-section-one .three-ways-to-help {
        width: 95%;
        height: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 2rem 0;
        margin: 1em auto 0;
    }

    .home-section-one .three-ways-to-help .division-line {
        height: 15em;
        transform: translateY(3em);
        border: .5px solid var(--site-light-gray);
    }

    .home-section-one .three-ways-to-help .way-to-help {
        width: 30%;
        margin: 1rem;
        padding: 1rem;
        box-sizing: border-box;
    }

    .home-section-one .three-ways-to-help  .way-to-help .card-heading {
        padding: 1rem 0 2rem;
    }
}
