.pop-privacy-policy-page {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    overflow-y: scroll;
    scrollbar-width: none;  
    -ms-overflow-style: none;  
}

.pop-privacy-policy-container {
    width: 100%;
    height: 100%;
}


