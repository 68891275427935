.login-page {
    width: 100%;
    height: 100%;
    padding: 8rem 1rem;
    box-sizing: border-box;
}

.login-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.login-container form {
    width: 100%;
}

.login-container form .form-group {
    width: 100%;
    display: flex;
    margin: 1rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.login-container form label {
    padding: .5rem 0;
}

.login-container form input {
    min-width: 10rem;
    width: 60%;
    padding: .5rem;
    font-size: 1rem;
    font-family: "Outfit",  sans-serif;
    border: 1px gray solid;
    box-sizing: border-box;
}

.login-container .password-wrapper {
    min-width: 10rem;
    width: 60%;
    height: 100%;
    position: relative;
}

.login-container .password-wrapper img {
    position: absolute;
    right: .8rem;
    top: .5rem;
    cursor: pointer;
    opacity: .4;
    width: 1.5rem;
}


.login-container form button {
    padding: .8rem 2rem;
    font-size: 1rem;
    background-color: var(--site-mid-gray);
    margin-top: 1rem;
}

.login-container .invalid {
    color: var(--site-call-for-action);
    width: 100%;
    height: 2rem;
    font-size: 1em;
    display: flex;
    align-items: center;
   }

.login-container .shake {
    animation: shakeForm 600ms ease-in-out;
}

.login-container .reset-password {
    margin-top: 2rem;
}

@keyframes shakeForm {
    0%, 100% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-.25rem);
    }
    40% {
        transform: translateY(.25rem);
    }
    60% {
        transform: translateY(-.15rem);
    }
    80% {
        transform: translateY(.15rem);
    }

}

@media screen and (min-width: 320px) {
    .login-container form .form-group {
        flex-direction: row;
        justify-content: space-between;
    }
    
}

@media screen and (min-width: 390px) {
    .login-container form input {
        width: 14rem;
    }
    
    .login-container .password-wrapper {
        width: 14rem;
    }

}

@media screen and (min-width: 400px) {
    .login-container {
        width: 20rem;
    }
}

