.section-four {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    background-color: var(--site-blue-gray);
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0;    
}


.section-four .home-section-four-block-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 2rem 1rem 5rem;
    flex-direction: column;
    box-sizing: border-box;
}

.home-section-four-block-wrapper .heading {
    font-size: 2.2rem;
    width: 100%;
    padding-bottom: 2rem;
    font-weight: 400;
    color: white;
    box-sizing: border-box;
}

.home-section-four-block-wrapper .content {
    font-size: 1em;
    width: 100%;
    line-height: 2;
    padding-bottom: 1rem;
    color: rgba(255, 255, 255, .8);
    overflow-y: hidden;
    box-sizing: border-box;
}

.home-section-four-block-wrapper .arrow-down-show-more {
    position: absolute;
    height: 2rem;
    bottom: .8rem;
    margin: 0 auto;
    width: 90%;
    display: none;
    justify-content: center;
}

.home-section-four-block-wrapper .arrow-down-show-more img {
    width: 1.5rem;
}


.section-four  .image-about {
    width: 100%;
    height: 30rem;
    overflow: hidden;
}

.section-four  .image-about .image-container {
    width: 100%;
    height: 100%;
    object-position: 10% 50%;
}

.section-four  .image-about img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (min-width: 450px) {

    .section-four {
        padding: 0;
    }

    .section-four .image-group .img-item,
    .section-four .image-group .area-x .img-item-blank,
    .section-four .image-group .area-z .img-item-blank {
        width: 11rem;
    }

    .home-section-four-block-wrapper .content {
        max-height: 100%;
        overflow-y: hidden;
    }

    .arrow-down-show-more img {
        display: none;
    }

}

@media screen and (min-width: 800px) {

    .section-four {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 3fr 6fr;
    }

    .section-four  .image-about {
        width: 100%;
        height: 100%;
    }
    
    .section-four  .image-about .image-container {
        width: 100%;
        height: 100%;
    }
    
    .section-four  .image-about img {
        width: 100%;
        height: 100%;
        object-fit: cover;

    }

    .section-four .home-section-four-block-wrapper {
        width: 100%;
        height: 100%;
        padding: 2rem 3rem;
        margin: 1rem auto;
        box-sizing: border-box;
    }

    .section-four    .home-section-four-block-wrapper .heading {
        width: 100%;
        padding-bottom: 2rem;
        margin: 0 auto;
        font-size: 2rem;
     }

     .section-four    .home-section-four-block-wrapper .content {
        box-sizing: border-box;
        width: 100%;
    }

    .section-four    .arrow-down-show-more img {
        display: none;
    }
}


@media screen and (min-width: 1000px) {

    .section-four {
        width: 100%;
        height: 32rem;
        padding: 0;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .section-four .image-about {
        width: 35%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .section-four .image-about .image-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .section-four .image-about .image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0;
    }

    .section-four .home-section-four-block-wrapper {
        width: 65%;
        max-width: 70%;
        height: auto;
       display: flex;
       flex-direction: column;
       justify-content: center;
       box-sizing: border-box;
       padding: 1rem 6rem 1rem 4rem;
       box-sizing: border-box;
    }

    .section-four    .home-section-four-block-wrapper .heading {
        width: 100%;
        height: auto;
        text-align: left;
        font-size: 2rem;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        padding-bottom: 1.5rem; 
        line-height: 1.5;
    }

    .section-four    .home-section-four-block-wrapper .content {
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    .section-four    .arrow-down-show-more img {
        display: none;
    }


} 

