.partner-page {
    width: 100%;
    height: 100%;
    padding: 6rem 1rem;
    box-sizing: border-box;
}

.partner-block {
    width: 95%;
    margin: 2rem auto;
    box-sizing: border-box;
}

.partner-block .header {
    font-size: 1.8rem;
    margin: 1rem 0;
    font-weight: 600;
}

.partner-block .content {
    display: flex;
    flex-direction: column;
    line-height: 2;
    margin-top: 2rem;
}

.partner-block .image-stats {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
}


.partner-block .content .image {
    width: 100%;
    aspect-ratio: 1.5;
    overflow: hidden;
    margin: 1rem 0;
    border-radius: 2rem;
    background-color: rgb(230, 230, 230);
}

.partner-block .content .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.partner-block .content .text {
    width: 100%;
}

.partner-block .content ul li {
    margin: .5rem 0;
    margin-left: -1rem;
}

.partner-contact-us a {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2;
}

.partner-contact-us img {
    width: 2rem;
    height: auto;
    margin-left: 1rem;
}

@media screen and (min-width: 600px) {

    .partner-page {
        padding: 8rem 1rem;
        width: 98%;
    }

    .partner-block .header {
        width: 100%;
        margin: 0 auto;
    }

    .partner-block .content {
        width: 100%;
        margin: 2rem auto;
        box-sizing: border-box;
    }

    .partner-block .image-stats {
        display: flex;
        width: 100%;
    }

    .partner-block .content .image {
        aspect-ratio: 1.2;
        margin: 1rem 0;
        border-radius: 4rem;
    }

    .partner-contact-us a {
        margin-top: 0;
        display: flex;
        flex-direction: row;
    }
}

@media screen and (min-width: 800px) {
    .partner-page {
        padding: 10rem 1rem;
    }

    .partner-block .content {
        width: 100%;
    }

    .partner-block .image-stats {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
        height: 100%;

    }

    .partner-block .content .image {
        flex: 0 0 40%;
        margin: 0 1rem 0 0;
        border-radius: 2rem;
        aspect-ratio: none;
        box-sizing: border-box;
    }

    .partner-block .content .text {
        flex: 0 0 60%;
        box-sizing: border-box;
    }

}

@media screen and (min-width: 1000px) {
    .partner-page {
        padding: 8rem 0;
    }

    .partner-block {
        width: 85%;
    }

    .partner-block .header {
        width: 100%;
    }

    .partner-block .content {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .partner-block .content .image {
        overflow: hidden;
        border-radius: 2rem;
    }

    .partner-block .content ul li {
        margin-left: 0;
    }
}