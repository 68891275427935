.reset-page {
    width: 100%;
    height: 100%;
    padding: 8rem 1rem;
    box-sizing: border-box;
}

.reset-page .reset-container {
    width: 95%;
    margin: 0 auto;
}

.reset-page .reset-container form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

}

.reset-page .reset-container form input,
.reset-page .reset-container form label {
    margin: .5rem 0;
} 

.reset-page .reset-container form input {
    padding: .5rem;
    font-family: 'Outfit',  sans-serif;
    font-size: 1rem;
    font-size: 1rem;
    font-weight: 300;
    width: 100%;
    box-sizing: border-box;
}

.reset-page .password-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.reset-page .password-wrapper img {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    opacity: .4;
    width: 1.5rem;
}

.reset-page .reset-container form button {
    margin: 1rem 0;
    background-color: var(--site-mid-gray);
    width: 100%;
    border-radius: 2rem;
    font-size: 1rem;
    padding: .8rem;
}

.reset-page .get-verification-link {
    margin: .5rem 0;
    cursor: pointer;
    font-weight: 400;
}

.reset-page .get-verification-link .get-code {
    color: var(--site-blue);
    display: flex;
    align-items: center;
}

.reset-page .get-verification-link .get-code img {
    width: 1.5rem;
    margin: 0 .5rem;
}

.reset-page .get-verification-link .code-received {
    color: var(--site-orange);
}

.reset-page .passwords-not-matched {
    color: var(--site-call-for-action);
    font-size: .9rem;
}

.reset-page .message {
    color: var(--site-mid-gray);
    padding: 2rem;
    background-color: var(--site-background);
    margin: 2rem 0;
    font-size: 1rem;
}

@media screen and (min-width: 760px) {
    .reset-page .reset-container {
        width: 70%;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1000px) {
    .reset-page .reset-container {
        width: 50%;
        margin: 0 auto;
    }
}