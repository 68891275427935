:root {
  --sweet-yellow: rgb(255, 185, 0);
  --deep-red: #9b0000;
  --main-color: rgb(25, 25, 25);
  --pink: rgb(250, 52, 187);
  --intense-red: #cb0018;
  --site-yellow: #ffc400;
  --site-green: #19ff00;
  --site-blue: #00c8dc;
  --site-violet: rgb(140, 0, 255);
  --site-orange: #e93402;
  --site-background: #1E2761;
  --site-background: #7A2048;
  --site-background: #3ea0e1;
  --site-background: #3ea0e1;
  --site-background: #2054db;
  --site-background: #E7E8D1;

  --site-background: #E7E8D1;
  --site-background: rgb(61, 159, 225);

  --sweet-yellow: rgb(255, 185, 0);
  --site-logo-color: white;
  --site-logo-stroke: white;
  --site-white-yellow: rgb(248, 246, 240);
  --site-dark-gray: #27313a;
  --site-nav-gray: #27313ae6;
  --site-light-gray: rgb(220, 220, 220);
  --site-mid-gray: #494f58;

  --site-call-for-action: #ff3700;
  --site-call-for-action: #e81212;
  
  --site-background: rgb(255, 185, 0);
   --site-background: rgb(250, 210, 0);
  --site-background: rgb(250, 200, 0);
  --site-background: rgb(250, 204, 0);

  --site-nav-student: #ff8800;
  --site-nav-student: #ff6a00de;
  --site-nav-student: #ff8332;
  --site-deep-red: #e70000;
  --site-blue-gray: #455772;
  --site-blue-gray: #44546e;

}

@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 100 900;
  src: url('/public/fonts/Outfit-VariableFont_wght.ttf') format('truetype');
  font-display: swap;
}

/*rgb(255, 188, 43);
rgb(82,82,82) -- light gray for the home about us section
  --site-mid-gray: #494f58 ;
      border: red solid 3px;
  --site-background: rgb(14, 166, 243);
  rgb(68,80,99);
  rgb(86,189,238)
*/

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: "Outfit", sans-serif;
  box-sizing: border-box;
  font-weight: 300;
  color: var(--site-dark-gray);
  scroll-behavior: smooth;
}

button {
  font-family: "Outfit", sans-serif;
  color: white;
  border: none;
  padding: .6em 1.8em;
  font-size: 1.2rem;
  border-radius: 0;
  font-weight: 200;
}

button:active {
  transform: translateY(2px);
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--site-dark-gray);
}

.home-main-sections {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0 auto 8rem;
}


.link-to-apply {
  display: flex;
  align-items: center;
  margin: 1em 0 0;
  font-size: 1.4em; 
  justify-content: center;
  font-weight: 600;
}


.link-to-apply img {
  width: 3em;
  margin: 0 0 0 1em;
  animation: apply-arrow-move 1500ms infinite ease-in-out;
}

.no-privilages {
  width: 100%;
  height: 100%;
  padding: 10rem 3rem;
  box-sizing: border-box;
  font-size: 1.2rem;
}

.no-privilages a {
  color: var(--site-blue)
}

.privacy-link {
  cursor: pointer;
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading img {
  width: 4rem;
  height: 4rem;
}

@keyframes apply-arrow-move {
  0%, 100% {
      transform: translateX(0);
  }
  50% {
      transform: translateX(.7em);
  }
  
}


@media screen and (min-width: 800px) and (min-height: 1000px) {
  html, body {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  html, body {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  html, body {
    font-size: 16px;
  }
}

@media screen and (min-width: 1350px) {
  html, body {
    font-size: 17px;
  }
}

@media screen and (min-width: 1400px) {
  html, body {
    font-size: 18px;
  }
}

@media screen and (min-width: 1530px) {
  html, body {
    font-size: 19.5px;
  }
}

@media screen and (min-width: 1650px) {
  html, body {
    font-size: 21px;
  }
}

@media screen and (min-width: 1900px) {
  html, body {
    font-size: 24px;
  }
}

@media screen and (min-width: 2500px) {
  html, body {
    font-size: 30px;
  }
}

@media screen and (min-width: 2500px) and (min-height: 1400px) {
  html, body {
    font-size: 33px;
  }
}

@media screen and (min-width: 2500px) and (min-height: 1700px) {
  html, body {
    font-size: 36px;
  }
}

@media screen and (min-width: 3000px) {
  html, body {
    font-size: 45px;
  }
}

@media screen and (min-width: 7000px) {
  html, body {
    font-size: 90px;
  }
}

