.hero-section {
    width: 100%;
    height: 100vh;
    min-height: 32rem;
    max-height: 50rem;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    background-color: var(--site-background);
}

.home-hero-overlay {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    background-color: rgba(21, 4, 4, 0.2);
    z-index: 1;
}

.hero-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit:cover;
  object-position: center;
}

.hero-image video {
    width: 100%;
    height: 100%;
    object-fit:cover;
    object-position: center;
}

.hero-heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    box-sizing: border-box;
    justify-content: center;
    z-index: 2;
}

.hero-heading .hero-header {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: start;
    font-size: clamp(2.5rem, 10vw, 3.5rem);
    font-weight: 500;
    line-height: 1;
    box-sizing: border-box;
    color: white;
    padding-left: 1rem;
    padding-top: 2rem;
  }


  .hero-heading .hero-header span {
     padding: 0;
  }

  .hero-heading .hero-header button {
    width: 5.8rem;
    height: 2.8rem;
    padding: 0;
    outline: none;
    color: white;
    border: none;
    margin: 2rem 0 0 0;
    font-size: 1.1rem;  
    border-radius: 0;
    font-weight: 300;
    cursor: pointer;
    border-radius: .5rem;
  }

  .hero-heading .hero-header .btn-donate {
    background-color: rgba(255, 0, 0, 0.9);
    color: white;
    margin-right: .8rem;
    position: relative;
    overflow: hidden;
  }

  .hero-heading .hero-header .btn-apply {
    background-color: rgba(255, 255, 255, 1);
    color: rgb(255, 30, 0);
    position: relative;
    overflow: hidden;
  }

  .hero-heading .hero-header .btn-donate::after,
  .hero-heading .hero-header .btn-apply::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 30px;
    left: 50%;
    bottom: 50%;
    transform: translateY(50%);
    background-color: transparent;
    z-index: 1;
}

.hero-heading .hero-header .btn-donate:hover::after,
.hero-heading .hero-header .btn-apply:hover::after {
    animation: pulseButton 700ms forwards ease-out;
}

@keyframes pulseButton {
    0% {
        box-shadow: 0 0 0 30px rgba(255, 255, 255, 1);
    }
    100% {
        box-shadow: 0 0 0 80px rgba(255, 255, 255, 0);
    }
}



.hero-heading .hero-subheader {
    width: auto;
    height: auto;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.2rem;
    line-height: 1;
    z-index: 4;
    box-sizing: border-box;
    flex-wrap: wrap;
    top: 0;
    left: 0;
    padding-left: 1rem;
  }

  .hero-heading .hero-subheader .subheader-group {
    width: 200px;
    flex-wrap: wrap;
    display: flex;
  }

.hero-heading .hero-subheader  span  {
    background-size: 100% 1em;
    background-repeat: no-repeat;
    background-position: 100% 0%;
  }

.hero-heading .hero-subheader span {
    margin-bottom: .5rem;
    margin-right: .5rem;
}

.hero-heading .hero-subheader .hero-sub-word {
  position: relative;
}

.hero-heading .hero-subheader .hero-sub-word::before {
  content: '';
  width: 100%;
  height: 100%;
  left:0 ;
  top: 0;
  position: absolute;
  padding: 1px;
  z-index: -1;
  border-radius: .3rem;
}

  .hero-heading .hero-subheader .hero-sub-word:nth-of-type(1)::before {
    background-color: rgba(255, 196, 0, 1);
  }

  .hero-heading .hero-subheader .hero-sub-word:nth-of-type(2)::before {
    background-image: linear-gradient(rgb(25, 255, 0, 1), rgb(28, 212, 43, 1));
  }
  .hero-heading .hero-subheader .hero-sub-word:nth-of-type(3)::before {
    background-image: linear-gradient(rgb(0, 200, 220, 1), rgb(0, 200, 220, 1));
  }
  .hero-heading .hero-subheader .hero-sub-word:nth-of-type(4)::before {
    background-image: linear-gradient(rgb(140, 0, 255, 1), #8c00ff);
  }

@media screen and (min-width: 350px) {

  .hero-heading  {
    padding-top: 3rem;
  }

  .hero-heading .hero-header {
  }

  .hero-heading .hero-header button {
    width: 7.5rem;
    height: 2.8rem;
    font-size: 1.15rem;
    margin-top: 2.5em;
  }

  .hero-heading .hero-subheader .subheader-group {
    width: 250px;
  }

  .hero-heading .hero-subheader {
    font-size: 1.4rem;
  }

}

@media screen and (min-width: 400px) {

  .hero-section {
    min-height: 47rem;
  }


  .hero-heading .hero-header button {
    font-size: 1.2rem;
    margin-top: 4.5rem;
    width: 7.8rem;
    height: 3rem;
  }

  .hero-heading .hero-subheader {
    width: 350px;
  }

  .hero-heading .hero-subheader {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 600px) {
  .hero-heading {
    padding-left: 3rem;
  }
}

@media screen and (min-width: 700px) {
  .hero-image {
    width: calc(100% - 25rem);
  } 
  .hero-heading {
    padding-left: 1rem;
  }

  .hero-heading .hero-header {
    font-weight: 200;
  }
  .home-hero-overlay {
    background-color: transparent;
  }
}

@media screen and (min-width: 850px) {

  .home-hero-overlay {
    background-color: transparent;
  }

}


@media screen and (min-width: 600px ) and (min-height: 800px) {

  .hero-section {
    min-height: 47rem;
  }

  .hero-heading {
    padding-left: 1rem;
  }

  .hero-heading .hero-subheader .subheader-group {
    transform: translateY(0);
    font-size: 1.8rem;
    width: 350px;
  }

  .hero-heading .hero-header button {
    font-size: 1.5rem;
    width: 8.5rem;
    height: 3.5rem;
    margin-top: 3.5em;
  }
}

@media screen and (min-width: 900px ) and (min-height: 1500px)  {
  .hero-section {
    width: 100%;
    max-height: 60vh;
  }

  .hero-heading .hero-subheader .subheader-group {
    transform: translateY(0);
  }
}


  @media screen and (min-width: 1100px) {

    .hero-section {
      min-height: 40rem;
      height: 100vh;
    }

    .hero-image {
      width: 67%;
      max-width: none;
    } 

    .home-hero-overlay {
      background-color: transparent;
    }

    .hero-heading {
      flex-direction: row;
      padding: 3em 4em 0 2.5em;
      align-items: center;
   }

   .hero-heading .hero-header {
    font-size: 3.4rem;
    line-height: 1.2;
    padding: 0;
    position: relative;
    font-weight: 200;
    height: auto;
   }

   .hero-heading .hero-header button {
    font-size: 1.15rem;
    width: 7.5rem;
    height: 3rem;
    margin-top: 3.5rem;
   }

   .hero-heading .hero-header .btn-apply {
    background-color: rgba(255, 255, 255, .75); 
   }

   .hero-heading .hero-subheader  .subheader-group {
    width: 6em;
    transform: translateY(0);
   }

   .hero-heading .hero-subheader {
    display: flex;
    font-size: 2.5rem;
    justify-content: flex-end;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    height: auto;
    transform: translateY(75%);
   }

}


@media screen and (min-width: 1200px) and (min-height: 800px) {

  .hero-heading {
    flex-direction: row;
    padding: 0 4em 0 2.5em;
  }
  
  .hero-image {
    width: 65%;
  } 

  .hero-heading .hero-header {
    font-size: 3.8rem;
    transform: translateY(11.5%);
  }



  .hero-heading .hero-subheader  .subheader-group {
    display: flex;
    font-size: 2.6rem;
  }

  .hero-heading .hero-header button {
    font-size: 1.2rem;
    width: 7.5rem;
    height: 3.5rem;
   }
}



@media screen and (min-width: 1279px) {
  .hero-image {
    width: 67%;
  } 
}

@media screen and (min-width: 1400px){

  .hero-heading .hero-header {
    transform: translateY(0);
  }

  .hero-heading .hero-subheader .subheader-group {
    display: flex;
    font-size: 2.5rem;
    transform: translateY(-1rem);
  }
}

@media screen and (min-width: 1500px) and (min-height: 900px){

  .hero-section {
    max-height: none;
  }

  .hero-heading {
    align-items: center;
  }

  .hero-heading .hero-header {
    font-size: 4rem;
    height: auto;
  }

  .hero-heading .hero-subheader {
    height: auto;
    transform: translateY(75%);
  }

  .hero-heading .hero-subheader .subheader-group {
    display: flex;
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1600px){

  .hero-section {
    height: 100vh;
  }

  .hero-heading .hero-header button {
    width: 8.5rem;
    height: 3.5rem;
    font-size: 1.3rem;
   }

   .hero-heading .hero-header {
    font-size: 4rem;
    transform: translateY(4vh);
   }

   .hero-heading .hero-subheader {
    transform: translateY(100%);
   }

   .hero-heading .hero-subheader .subheader-group {
    width: 6em;
    display: flex;
    font-size: 2.6rem;
    padding: 0;
    margin: 0;
   }
}

@media screen and (min-width: 1800px) {
  .hero-heading .hero-subheader .subheader-group {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1900px) {
  .hero-heading .hero-header {
    font-size: 4rem;
   }

   .hero-heading .hero-subheader .subheader-group {
    width: 6em;
    display: flex;
    font-size: 2.8rem;
    padding: 0;
    margin: 0;
   }

   .hero-heading .hero-header button {
    font-size: 1.2rem;
   }


    .hero-heading .hero-subheader {
      transform: translateY(75%);
     }
  
}

