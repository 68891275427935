.donate-section {
    padding: 4rem 0 8rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

.donate-header {
  width: 85%;
  min-height: 90vh;
  height: 100%;
  margin: 0 auto;
  line-height: 1.5;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 400;
  align-items: center;
  display: flex;
  padding: 2rem 0;
  box-sizing: border-box;
}

.loop {
    width: 100%;
    position: relative;
    transition: 5s animation ease-in-out;
    display: flex;
    flex-wrap: nowrap;
    padding: 1.55em 0 1.5em;
    margin: 2rem 0 0 0;
    background-image: url('../../public/images/donate/film-strip-light-gray.svg');
    background-size: contain;
    background-position: center;
    background-repeat: repeat;
}

.loop img {
    width: auto;
    height: 13em;
    margin: 0;
} 

.loop .image-container-one {
  display: flex;
    animation: loop 50s -25s linear infinite;
  }
  
.loop .image-container-two {
  display: flex;
    animation: loop2 50s linear infinite;
}
  

@keyframes loop {
    0% {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  @keyframes loop2 {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-200%);
    }
  }
  
  .donate-heading {
    font-size: 1.8rem;
    font-weight: 800;
    margin: 4rem auto 1rem;
    text-align: center;
    padding: 0 1rem;
  }

.donate-subheading {
  font-size: 1.1rem;
  font-weight: 450;
  width: 100%;
  padding: 0 1rem;
  margin: 2rem 0 2rem;
  text-align: center;
  line-height: 2;
  box-sizing: border-box;
}

.donate-subheading .thank-you {
  white-space: nowrap;
  display: inline-block;
}

  .donate-options {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 1rem 4em;
  }

  .donate-option {
    width: 90%;
    height: 100%;
    padding: 1rem .5em 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .donate-option img {
    margin: 1rem auto 2rem;
    width: 4.5rem;
  }

.donate-section-header {
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 800;
}

.donate-option:nth-child(1) .donate-section-header {
  color: var(--site-orange)
}

.donate-option:nth-child(2) .donate-section-header {
  color: var(--sweet-yellow)
}

.donate-option:nth-child(3) .donate-section-header {
  color: var(--site-blue)
}

.donate-section-text {
  font-size: 1rem;
  width: 100%;
  text-align: center;
  line-height: 2;
  margin-bottom: 3rem;
}

.donate-option .donate-btn a {
  font-size: 1em;
  font-weight: 400;
  background-color: white;
  width: 7.5em;
  height: 2.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Outfit",  sans-serif;
  cursor: pointer;
  border-radius: 0.4rem;
  transition: 400ms ease;
}

.donate-option:nth-child(1) .donate-btn a {
  color: var(--site-orange);
  border: 2.5px solid var(--site-orange);
}

.donate-option:nth-child(1) .donate-btn:hover a {
  background-color: var(--site-orange);
  color: white;
}

.donate-option:nth-child(2) .donate-btn a {
  color: var(--sweet-yellow);
  border: 2.5px solid var(--sweet-yellow);
}

.donate-option:nth-child(2) .donate-btn:hover a {
    background-color: var(--sweet-yellow);
    color: white;
}
  
.donate-option:nth-child(3) .donate-btn a {
  color: var(--site-blue);
  border: 2.5px solid var(--site-blue);
}

.donate-option:nth-child(3) .donate-btn:hover a {
  background-color: var(--site-blue);
  color: white;
}


/* DONATE BUTTONS */

.donate-option .paypal-donate-btn {
  font-size: 1em;
  font-weight: 400;
  background-color: white;
  width: 7.5em;
  height: 2.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Outfit",  sans-serif;
}

.donate-option:nth-child(1) .paypal-donate-btn {
  color: var(--site-orange);
  border: 2.5px solid var(--site-orange);
}

.donate-option:nth-child(1) .paypal-donate-btn:hover {
  background-color: var(--site-orange);
  color: white;

}

.donate-option:nth-child(2) .paypal-donate-btn {
  color: var(--sweet-yellow);
  border: 2.5px solid var(--sweet-yellow);
}

.donate-option:nth-child(2) .paypal-donate-btn:hover {
    background-color: var(--sweet-yellow);
    color: white;
}
  
.donate-option:nth-child(3) .paypal-donate-btn {
  color: var(--site-blue);
  border: 2.5px solid var(--site-blue);
}

.donate-option:nth-child(3) .paypal-donate-btn:hover {
  background-color: var(--site-blue);
  color: white;
}

.overlay-ad {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 150;
  opacity: 0;
  transition-delay: 800ms;
  transition: 800ms ease;
  overflow-y: auto;
  padding: 1rem;
  box-sizing: border-box;
}

@media screen and (min-width: 600px) and (max-height: 580px) {
  .overlay-ad {
    align-items: flex-start;
    padding: 3rem 1rem;
  }
}

.overlay-ad.visible {
  display: flex !important;   
  visibility: visible;
  transform: translateZ(0);
}

.overlay-ad.fade-in {
  opacity: 1;
}

.tshirt-ad {
  width: 80%;
  max-width: 20rem;
  min-height: 30rem;
  height: auto;
  display: flex;
  flex-direction: column;
  color: white;
  box-sizing: border-box;
  border-radius: 2rem;
  align-items: center;
  position: relative;
  transform: translateY(0.5rem);
}

.tshirt-ad .bg-3 {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  background-color: var(--site-orange);
  box-sizing: border-box;
  padding: 2rem 1rem;

}

.tshirt-ad .image {
  width: 100%;
  height: 12rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.tshirt-ad .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.tshirt-ad .close {
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  opacity: .5;
  cursor: pointer;
}

.tshirt-ad .close img {
  width: 2rem;
  height: 2rem;
}

.tshirt-ad .content {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 1rem;
  text-align: center;
  box-sizing: border-box;
 }

.tshirt-ad .content div {
  font-size: 1.5rem;
  font-weight: 500;
}

.tshirt-ad button {
  font-size: 1.3rem;
  padding: 1rem 1.5rem;
  font-weight: 400;
  background-color: white;
  color: red;
  width: 14rem;
  border-radius: 1rem;
 box-shadow: 5px 20px 25px rgba(0, 0, 0, 0.3);
 transform: translateY(2rem);
}

@media screen and (min-width: 600px) {

  .donate-section {
    padding: 6.5rem 0;
  }


  .tshirt-ad {
    min-width: 20rem;
    width: 50%;
    max-width: none;
    transform: translateY(1rem);
  }

  .tshirt-ad .image {
    height: 15rem;
  }

  .tshirt-ad .content div {
    font-size: 1.8rem;
  }

  .tshirt-ad button {
    font-size: 1.3rem;
    transform: translateY(2rem);
  }

  .donate-header {
    width: 85%;
    min-height: 90vh;
    height: 100%;
    margin: 0 auto;
    line-height: 1.5;
    font-size: 1.3rem;
    text-align: center;
  }

  .donate-subheading {
    font-size: 1.2rem;
    padding: 0 4rem;
  }

  .donate-header .arrow {
    margin-top: 3rem;
  }

  .donate-header .arrow img {
    width: 4rem;
    height: 4rem;
    animation: moveDonateArrow 1600ms infinite ease;
  }

  @keyframes moveDonateArrow {
    0%, 100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(.6rem);
    }
  }

  .donate-options {
    width: 60%;
    height: 100%;
    padding: 1em 0 3em;
  }

  .donate-option {
    width: 100%;
    padding: 1em .5em;
    margin-bottom: 4rem;
  }

  .donate-section-header {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    font-weight: 800;
  }

}

@media screen and (min-width: 760px) {
  .donate-header {
    font-size: 1.8rem;
    min-height: 90vh;
  }
}

@media screen and (min-width: 1000px) {

  .donate-section {
    padding: 6rem 0 8rem;
  }

  .tshirt-ad {
    min-width: 20rem;
    width: 30%;
    height: auto;
    min-height: 25rem;
    transform: translateY(0);
  }

  .tshirt-ad button {
    left: 28%;
  }

  .donate-header {
    width: 85%;
    height: 95vh;
    margin: 0 auto;
    font-weight: 150;
    font-size: 1.8rem;
  }

  .donate-subheading {
    width: 85%;
  }
  
  .donate-options {
    width: 95%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .donate-option {
    width: 30%;
    align-items: center;
    margin: 0 1rem; 
    box-sizing: border-box;
    justify-content: flex-start;
  }

  .donate-section-text {
    text-align: center;
    line-height: 2;
    margin-bottom: 5rem;
  }

  .donate-section-header {
    font-size: 1.6rem;
    margin: 1rem 0 2.5rem;
    text-align: left;
  }

}

@media screen and (min-width: 1700px) {

  .donate-section {
    padding: 7rem 0;
  }

  .donate-header {
    width: 85%;
    min-height: 90vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .donate-options {
    width: 85%;
  }

}


/* CORPORATE ================================================================================ */


.donate-stocks-page {
  width: 100%;
  height: 100%;
  padding: 6rem 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden ;
}

.corporate-section-heading {
  font-size: 1.8rem;
  font-weight: 800;
  margin: 0 auto 1rem;
  text-align: center;
  padding: 0 1rem;
}

.donate-stocks-subheading {
  width: 100%;
  margin: 2rem 1rem 4rem;
  font-size: 1.1rem;
  font-weight: 450;
  line-height: 2;
  text-align: center;
  padding: 0 1rem;
  box-sizing: border-box;
}

.donate-options-corporate-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.donate-option-corporate {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin: 2rem auto 3rem;
  z-index: 3;
  box-sizing: border-box;
  background-color: rgba(0,0,0, .03);
  flex: 1;
  border-radius: 2rem 0 2rem 0;
}

.left-panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--site-background);
  border-radius: 2rem 0 2rem 0;
  overflow: hidden;
}

.donate-option-corporate-image {
  width: 4.5rem;
  height: 4.5rem;
  overflow: hidden;
  position: relative;
  margin: .5rem auto;
}

.donate-option-corporate-image img {
  width: 100%;
  height: 100%;
}
  
.donate-option-corporate .donate-option-corporate-header {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 auto;
  text-align: center;
}

.right-panel {
  width: 100%;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
}

.donate-option-corporate .donate-option-corporate-content {
  line-height: 2;
  padding: 0;
  margin: 0;

}


.donate-contact-us {
margin: 4rem 1rem 0;
display: flex;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
font-weight: 600;
font-size: 1.5rem;
}

.donate-contact-us img {
  width: 2rem;
  margin: 0 1rem;
  transform: translateY(.5rem);
}


@media screen and (min-width: 600px){

  .donate-stocks-page {
      padding: 6rem 0;
  }

  .donate-options-corporate-wrapper {
      width: 85%;
      margin: 0 auto;
    }
  
    .corporate-section-heading { 
      margin: 1rem 0 0;
      padding: 1rem 0;
      font-size: 1.8rem;
    }

    .donate-stocks-subheading {
      font-size: 1.2rem;
    }
  
    .donate-option-corporate {
      width: 95%;
      min-height: 16rem;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }
  
    .left-panel {
      width: 30%;
      height: auto;
      flex-grow: 1;
    }
  
    .right-panel {
      width: 70%;
      height: auto;
      flex-grow: 1;
    }
  
  
    .donate-contact-us {
      margin: 4rem 1em 0;
    }

    .donate-stocks-subheading {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
}

@media screen and (min-width: 800px) and (max-width: 1000px) and (min-height: 1000px){

  .donate-stocks-page {
      padding: 8rem 0;
  }
}



@media screen and (min-width: 1000px) {

  .donate-stocks-page {
      padding: 6rem 0;
  }

  .donate-options-corporate-wrapper {
    width: 90%;
    height: 100%;
    margin: 1rem auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
  }

  .group-stocks {
    width: 100%;
    height: auto;
    margin: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  } 

  .donate-option-corporate {
    min-height: 16rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 1rem;
    width: calc(50% - 2rem);
    flex-grow: 1;
  }

  .left-panel {
    width: 30%;
    height: auto;
    flex-grow: 1;
  }

  .right-panel {
    width: 70%;
    height: auto;
    flex-grow: 1;
    align-items: center;
  }

  .corporate-section-heading { 
    margin: 2rem 0 0;
    padding: 1rem 0;
    font-size: 1.8rem;
  }

  .donate-stocks-subheading {
      width: 85%;
      font-size: 1.2rem;
      text-align: center;
    }

  .donate-contact-us {
    flex-direction: row;
  }

  .donate-contact-us span:nth-of-type(1) {
    margin-right: .5rem;
  }

}

@media screen and (min-width: 1200px) {

  .donate-stocks-page {
      padding: 6.5rem 0;
  }
}


@media screen and (min-width: 1600px) {
  .donate-stocks-page {
      padding: 7rem 0;
  }

  .donate-options-corporate-wrapper {
    width: 85%;
  }

}

/* BECOME A PARTNER BANNER */

.donate-banner-partner {
  width: 100%;
  height: 100%;
  background-color: var(--site-mid-gray);
  display: flex;
  margin: 2rem 0 4rem;
  flex-direction: column;
}

.donate-banner-partner .image {
  width: 100%;
  height: 100%;
}

.donate-banner-partner .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 15% center;
}

.donate-banner-partner .text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 1rem;
  box-sizing: border-box;
  line-height: 2;
  font-size: 1.2rem;
  color: white;
}

.donate-banner-partner .text .donate-banner-header {
  font-size: 1.8rem;
}

.donate-banner-partner .text .donate-banner-body {
  opacity: .8;
}

.donate-banner-partner .text .learn-more a {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  color: white;
  width: 10rem;
  padding: 0 0 0 1rem;
  border: 1px white solid;
  border-radius: 2rem;
  position: relative;
  transition: 600ms ease;
}

.donate-banner-partner .text .learn-more a::after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
}

.donate-banner-partner .text .learn-more a:hover::after {
  width: 100%;
}

.donate-banner-partner .text .learn-more a img {
  width: 2.8rem;
  height: 2.8rem;
  margin-left: 1rem;
  transition: 600ms ease;
}

.donate-banner-partner .text .learn-more:hover a img {
  transform: rotate(45deg);
}




@media screen and (min-width: 760px) {
  .donate-banner-partner {
    height: 28rem;
    align-items: center;
    flex-direction: row;
  }
  
  .donate-banner-partner .image {
    width: 40%;
    height: 100%;
  }
  
  .donate-banner-partner .image img {
    opacity: 1;
  }

  .donate-banner-partner .text {
    width: 60%;
    height: 100%;
    padding: 3rem 3rem;
    justify-content: center;
  }
}